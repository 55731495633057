import { isErrors } from '@/utilities/graphQLClient/error';

export const NARRATIVE_ERROR_CODES = {
  'NRTV-001': 'narrativeNotFound', // Narrative was not found in the database.
  'NRTV-023': 'invalidInputRef', // Input ref was in an invalid format.
  'NRTV-024': 'narrativeNotPublic', // The narrative exists but is not public.
  'NRTV-025': 'narrativeBlocked', // The narrative exists but is blocked via manual moderation.
  'NRTV-026': 'narrativeSoftDeleted', // The narrative did exist, but has been soft deleted.
  UNKNOWN: 'unknown', // Unknown error - perhaps we need to update the error codes
} as const;

export type NarrativeErrorCodeTranslated =
  (typeof NARRATIVE_ERROR_CODES)[keyof typeof NARRATIVE_ERROR_CODES];

export const getNarrativeErrorCode = (
  error: unknown
): NarrativeErrorCodeTranslated => {
  const errors =
    error && typeof error === 'object' && 'errors' in error
      ? error.errors
      : error;
  const firstError = isErrors(errors) ? errors[0] : null;
  if (!firstError) return NARRATIVE_ERROR_CODES.UNKNOWN;
  const { code } = firstError.extensions ?? {};
  if (!code) return NARRATIVE_ERROR_CODES.UNKNOWN;

  const translatedCode =
    NARRATIVE_ERROR_CODES[code as keyof typeof NARRATIVE_ERROR_CODES] ??
    NARRATIVE_ERROR_CODES.UNKNOWN;

  return translatedCode;
};

export const narrativeRetry = (failureCount: number, error: unknown) => {
  const translatedErrorCode = getNarrativeErrorCode(error);
  if (translatedErrorCode !== NARRATIVE_ERROR_CODES.UNKNOWN) return false;
  return failureCount < 3;
};
