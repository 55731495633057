import { useMemo } from 'react';
import type { useNarrativeNotFoundNarratives } from './useNarrativeNotFoundNarratives';
import type { ResolvedQueryData } from '@/utilities/genericTypes';
import type { NarrativeFeedType } from '../utils';

export const useFeedTypeAndNarratives = ({
  errorFeedType,
  data,
}: {
  errorFeedType: NarrativeFeedType;
  data: ResolvedQueryData<typeof useNarrativeNotFoundNarratives>;
}) => {
  return useMemo(() => {
    if (!data) return { feedType: 'global', narratives: [] };
    const weeklyPicks = data.narrativeWeeklyPicks;
    const narrativesByCanonicalUrl =
      data.narrativesByCanonicalUrl?.edges.map((narrative) => narrative.node) ??
      [];
    const globalNarratives =
      data.narratives?.edges.map((narrative) => narrative.node) ?? [];

    if (errorFeedType === 'stock' && narrativesByCanonicalUrl.length) {
      return {
        feedType: 'stock',
        narratives: narrativesByCanonicalUrl,
      };
    }

    if (errorFeedType === 'weeklyPicks' && weeklyPicks.length) {
      return {
        feedType: 'weeklyPicks',
        narratives: weeklyPicks,
      };
    }

    return {
      feedType: 'global',
      narratives: globalNarratives,
    };
  }, [errorFeedType, data]);
};
