import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

export const Content = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${({ theme }) => theme.x3spacePx + 'px'};
  ${media.mdUp`
    padding: ${({ theme }) => theme.x5spacePx + 'px'};
  `}
`;
