import { cn } from '@simplywallst/ui-core/next';
import type { ComponentProps, ReactNode } from 'react';

type Props = {
  children: ReactNode;
} & ComponentProps<'section'>;

export const NarrativeNotFoundLayout = ({
  children,
  className,
  ...rest
}: Props) => {
  return (
    <section
      className={cn(
        'grid grid-cols-1 gap-x2 @[668px]/main:grid-cols-2 @[1024px]/main:grid-cols-3',
        className
      )}
      {...rest}
    >
      {children}
    </section>
  );
};
