import { media } from '@simplywallst/ui-core';
import { styled, css, keyframes } from 'styled-components';

const pulse = (from: string, to: string) => keyframes`
  0%, 100% {
    background-color: ${from};
  }
  50% {
    background-color: ${to};
  }
`;

export const LogoWrapper = styled.div<{
  $isLoading: boolean;
  $size: number;
  $sizeLg: number;
}>`
  display: flex;
  justify-content: center;
  height: ${({ $size }) => `${$size}px`};
  width: ${({ $size }) => `${$size}px`};
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.whiteRGBA(0.3)};
  border-radius: ${({ theme }) => theme.x1spacePx}px;
  animation: ${({ $isLoading, theme }) =>
    $isLoading
      ? css`
          ${pulse(
            theme.color.phantom,
            theme.color.darkerGray
          )} 2s infinite forwards
        `
      : 'none'};

  ${media.lgUp`
    height: ${({ $sizeLg }) => `${$sizeLg}px`};
    width: ${({ $sizeLg }) => `${$sizeLg}px`};
  `}
`;

export const StyledImg = styled.img<{ $isLoading: boolean }>`
  object-fit: scale-down;
  transition:
    opacity 0.3s ease,
    background-color 0.3s ease;
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  background-color: ${({ theme }) => theme.color.white};
  max-width: 100%;
  height: auto;
  border: 0;
`;

export const FallbackSvg = styled('svg')`
  vertical-align: text-bottom;

  text {
    color: #fff;
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
`;
