import { useContext } from 'react';
import {
  NarrativeWatchCompanyContext,
  initialState,
} from '../NarrativeWatchCompanyProvider';

export const useNarrativeWatchCompanyContext = () => {
  const context = useContext(NarrativeWatchCompanyContext);

  if (!context) {
    console.error(
      'useNarrativeWatchCompanyContext must be used within a NarrativeWatchCompanyProvider'
    );

    return initialState;
  }

  return context;
};
