import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';
import { useNarrativeInvalidate } from '../../hooks/useNarrativeInvalidate';
import type { QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

type InvalidateSetPrimaryFn = (props: {
  narrativeId: string;
  companyId: string;
  unset?: boolean; // Whether the primary narrative is being unset
}) => void | Promise<void>;

const NarrarativeInvalidationContext = createContext<{
  invalidateSetPrimary: InvalidateSetPrimaryFn | null;
}>({
  invalidateSetPrimary: null,
});

type Helpers = {
  queryClient: QueryClient;
  invalidateExcept: ReturnType<
    typeof useNarrativeInvalidate
  >['invalidateExcept'];
  invalidatePriority: ReturnType<
    typeof useNarrativeInvalidate
  >['invalidatePriority'];
  invalidateAll: ReturnType<typeof useNarrativeInvalidate>['invalidate'];
};

export type OnSetPrimaryPayload = {
  narrativeId: string;
  companyId: string;
  unset?: boolean;
};

export type OnSetPrimaryInvalidator = (
  helpers: Helpers,
  payload: OnSetPrimaryPayload
) => void | Promise<void>;

type Props = {
  children: ReactNode;
  onSetPrimary?: OnSetPrimaryInvalidator;
};

/**
 * Wrap components that trigger a narrative mutation such as setPrimary (more can be added in the future)
 * and implement the invalidation logic there. If nothing is provided, all narrative queries will be invalidated.
 * Note: if you are implementing a new component that triggers a narrative mutation, make sure to call it with an appropriate
 * payload after the mutation has been successfully completed.
 *
 * @example
 * const { invalidateSetPrimary } = useNarrativeInvalidationBoundary()
 *
 * const handleSetPrimary = async () => {
 *   await setPrimary()
 *   await invalidateSetPrimary({ narrativeId, companyId })
 * }
 */
export const NarrativeInvalidationBoundary = ({
  children,
  onSetPrimary,
}: Props) => {
  const {
    invalidate: invalidateAll,
    invalidateExcept,
    invalidatePriority,
  } = useNarrativeInvalidate();
  const queryClient = useQueryClient();

  const helpers = {
    queryClient,
    invalidateExcept,
    invalidatePriority,
    invalidateAll,
  };

  const invalidateSetPrimary = (payload: OnSetPrimaryPayload) => {
    if (typeof onSetPrimary === 'function') {
      return onSetPrimary(helpers, payload);
    }

    return invalidateAll();
  };

  return (
    <NarrarativeInvalidationContext.Provider value={{ invalidateSetPrimary }}>
      {children}
    </NarrarativeInvalidationContext.Provider>
  );
};

export const useNarrativeInvalidationBoundary = () => {
  const context = useContext(NarrarativeInvalidationContext);
  const { invalidate: invalidateAll } = useNarrativeInvalidate();

  return {
    ...context,
    invalidateSetPrimary: context.invalidateSetPrimary ?? invalidateAll,
  };
};
