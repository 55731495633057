import { gql } from '@/utilities/gql';
import { useSnackbar } from '@/features/snackbar';
import { makeGqlMutation, useGqlQueryInvalidate } from '@/utilities/gqlQuery';

const MUTATION = gql(
  `
  mutation deleteNarrative(
    $narrativeId: String!
  ) {
    deleteNarrative(id: $narrativeId)
  }
`
);

export const useDeleteNarrative = ({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const { addSnackbar } = useSnackbar();
  const invalidate = useGqlQueryInvalidate();

  return makeGqlMutation(MUTATION)({
    onSuccess: async () => {
      await invalidate(['narrative']);
      addSnackbar({
        type: 'positive',
        lifeTime: 1000,
        message: 'Successfully deleted narrative',
      });
      onSuccess?.();
    },
    onError: () => {
      addSnackbar({
        type: 'negative',
        lifeTime: 2000,
        message: 'Failed to delete narrative',
      });
      onError?.();
    },
  });
};
