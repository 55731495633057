import { useDeleteNarrative } from '@/pages/Narrative/hooks/useDeleteNarrative/useDeleteNarrative';
import WarningSolidSVG from '@simplywallst/ui-core/icons/WarningSolid';
import { Button, Modal } from '@simplywallst/ui-core/next';
import { useTracking } from 'react-tracking';

export const NarrativeDeleteConfirmation = ({
  narrativeId,
  isDeleteConfirmationOpen,
  setIsDeleteConfirmationOpen,
  onSuccess,
}: {
  narrativeId: string;
  isDeleteConfirmationOpen: boolean;
  setIsDeleteConfirmationOpen: (isDeleteConfirmationOpen: boolean) => void;
  onSuccess?: () => void;
}) => {
  const { mutate: deleteNarrative } = useDeleteNarrative({
    onSuccess: async () => {
      setIsDeleteConfirmationOpen(false);
      onSuccess?.();
    },
  });

  const { trackEvent } = useTracking();

  const handleDelete = () => {
    trackEvent({
      action: 'click',
      target: 'delete-narrative',
    });
    deleteNarrative({ narrativeId });
  };

  return (
    <>
      <Modal
        title={
          <div className="flex items-center gap-x-2">
            <WarningSolidSVG
              height="3rem"
              width="3rem"
              className="fill-warning"
            />
            <div className="text-sm">
              Are you sure you want to delete your narrative?
            </div>
          </div>
        }
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        className="lg:max-w-[504px]"
      >
        <Modal.Body>
          <div className="text-xs text-soft">
            This action is permanent and cannot be undone. Once deleted, you
            won&apos;t be able to recover this narrative.
          </div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={() => setIsDeleteConfirmationOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes, Delete
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
