import { useEffect, useId } from 'react';
import { useSnackbar } from '../context';
import { isEqual, isUndefined, omitBy, pick } from 'lodash';
import type { NewSnackbarItem } from '../context/snackbarReducer';
import type { SnackbarItem } from '../types';

const getSnackbarOptionsClean = (
  options: Partial<SnackbarItem>
): Partial<SnackbarItem> =>
  omitBy(
    pick(options, [
      'type',
      'message',
      'headline',
      'actionLabel',
      'secondaryActionLabel',
    ]),
    isUndefined
  );

const useSnackbarToggle = (
  isActive: boolean,
  options: Omit<NewSnackbarItem, 'id'>
) => {
  const { addSnackbar, removeSnackbar, state } = useSnackbar();
  const snackbarId = useId();
  const activePayload = state.entities[snackbarId];

  const snackbarVisible = !!activePayload;
  const requiresUpdate =
    snackbarVisible &&
    !isEqual(
      getSnackbarOptionsClean(activePayload),
      getSnackbarOptionsClean(options)
    );

  useEffect(() => {
    if (!snackbarId) return;

    if (isActive && (!snackbarVisible || requiresUpdate)) {
      addSnackbar({ ...options, id: snackbarId });
    }

    if (!isActive && snackbarVisible) {
      removeSnackbar({ id: snackbarId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, snackbarId, requiresUpdate, snackbarVisible, removeSnackbar]);

  useEffect(() => {
    return () => {
      removeSnackbar({ id: snackbarId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snackbarId]);
};

export default useSnackbarToggle;
