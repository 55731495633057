import { Badge } from '@simplywallst/ui-core/next';
import { formatDistanceToNow, differenceInHours } from 'date-fns';

type Props = {
  updatedAt: string | null;
  lastFeaturedAt: string | null;
};

export const NarrativeCardTimeBadge = ({
  updatedAt,
  lastFeaturedAt,
}: Props) => {
  const featuredDate = lastFeaturedAt ? new Date(lastFeaturedAt) : null;
  const isNew =
    featuredDate && differenceInHours(new Date(), featuredDate) < 24;

  if (isNew)
    return (
      <div className="grid justify-end">
        <Badge tone="attention">New</Badge>
        <span className="sr-only"> narrative</span>
      </div>
    );

  if (!updatedAt) return null;

  const updatedDate = new Date(updatedAt);
  const isUpdated =
    updatedDate && differenceInHours(new Date(), updatedDate) < 24;
  if (isUpdated)
    return (
      <div className="grid justify-end">
        <Badge tone="attention">Updated</Badge>
        <span className="sr-only"> narrative</span>
      </div>
    );

  return (
    <div className="grid min-h-x3 justify-end text-xs text-softer">
      {formatDistanceToNow(updatedDate, {
        addSuffix: true,
      })}
      <span className="sr-only"> author updated this narrative</span>
    </div>
  );
};
