import { gql } from '@/utilities/gql';
import { NARRATIVE_OWNER_FRAGMENT } from '../NarrativeAuthor';
import { NARRATIVE_URL_COMPANY_FRAGMENT } from '@/pages/Narrative/utils/getNarrativeUrl';

export const NARRATIVE_CARD_FRAGMENT = gql(
  `
  fragment NarrativeCardFields on Narrative @_unmask {
    id
    slug
    updatedAt
    lastFeaturedAt
    primaryCount
    title
    isPublic
    commentKey
    excerpt
    type
    isPrimary
    company {
      id
      tickerSymbol
      name
      data {
        currencyInfo
      }
      analysisValue {
        lastSharePrice
        npvPerShare
        priceTargetAnalystCount
      }
      info {
        url
      }
      ...NarrativeUrlCompanyFields
    }
    owner {
      ...NarrativeOwnerFields
    }
    valuation {
      fairValue
      revenuePercentageChange
    }
    voteCount {
      upvotes
    }
  }
`,
  [NARRATIVE_URL_COMPANY_FRAGMENT, NARRATIVE_OWNER_FRAGMENT]
);
