import { pluralise } from '@/utilities/pluralise';

type GetNarrativeAuthorRoleProps = {
  isAiNarrative?: boolean | null;
  priceTargetAnalystCount?: number | null;
  role?: string | null;
};

export const getNarrativeAuthorRole = ({
  isAiNarrative,
  priceTargetAnalystCount,
  role,
}: GetNarrativeAuthorRoleProps) => {
  if (isAiNarrative) {
    if (!priceTargetAnalystCount) return 'Consensus Narrative from Analysts';

    return `Consensus Narrative from ${priceTargetAnalystCount} ${pluralise(
      priceTargetAnalystCount,
      'Analyst',
      'Analysts'
    )}`;
  }
  return role || 'Community Contributor';
};
