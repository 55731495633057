import { useCallback } from 'react';
import { useLazyScript } from './useScript';

export function useTypeform() {
  const [loadScript] = useLazyScript('https://embed.typeform.com/embed.js', {
    crossOrigin: undefined,
  });

  const getTypeform = useCallback(async () => {
    if (RUNTIME_ENV !== 'server') {
      await loadScript();
      return window.typeformEmbed;
    }

    return undefined;
  }, [loadScript]);

  return getTypeform;
}
