type Width = number;
type Height = number;

interface Options {
  src: string | null;
  width?: Width;
  height?: Height;
  // Default value 'cover'
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  // Default value 80
  quality?: number;
  // Values between 0 (no blur) - 250 (maximum blur)
  blur?: number;
  // A value of 1.0 equals no change, a value of 0.5 equals half brightness, and a value of 2.0 equals twice as bright. 0 is ignored.
  brightness?: number;
  // A value of 1.0 equals no change, a value of 0.5 equals low contrast, and a value of 2.0 equals high contrast. 0 is ignored.
  contrast?: number;
}

interface ResponsiveOptions {
  src: string | null;
  breakpoints: Width[] | Omit<Options, 'src'>[];
}

export const BASE_URL = 'https://simplywall.st';

export function getStaticUrl(file: string) {
  return `${BASE_URL}/static/images/${file}`;
}

export function getCloudflareImageUrl({
  src,
  width,
  height,
  fit = 'cover',
  quality = 80,
  blur = 0,
  brightness,
  contrast,
}: Options) {
  if (src === '' || src === null) return '';

  /**
   * Options
   * https://developers.cloudflare.com/images/transform-images/transform-via-workers/
   */
  const options = [
    'format=auto',
    `fit=${fit}`,
    typeof width === 'number' && `w=${width}`,
    typeof height === 'number' && `h=${height}`,
    `q=${quality}`,
    `blur=${blur}`,
    brightness !== undefined && `brightness=${brightness}`,
    contrast !== undefined && `contrast=${contrast}`,
  ];

  return `${BASE_URL}/cdn-cgi/image/${options
    .filter(Boolean)
    .join(',')}/${src}`;
}

export function getResponsiveImage(options: ResponsiveOptions) {
  return {
    srcSet: options.breakpoints
      .map((breakpoint: ResponsiveOptions['breakpoints'][number]) => {
        if (typeof breakpoint === 'number') {
          return `${getCloudflareImageUrl({
            src: options.src,
            width: breakpoint,
          })} ${breakpoint}w`;
        } else {
          return `${getCloudflareImageUrl({
            src: options.src,
            width: breakpoint.width,
            height: breakpoint.height,
            fit: breakpoint.fit || 'cover',
          })} ${breakpoint.width}w`;
        }
      })
      .join(','),
  };
}

export function useCloudflareImage(options: Options) {
  return getCloudflareImageUrl(options);
}

export function useResponsiveCloudflareImage(options: ResponsiveOptions) {
  return getResponsiveImage(options);
}
