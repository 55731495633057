// import { hslToRgb } from '@simplywallst/ui-theme/lib/src/utils/colors';
import { hslToRgb } from '@utilities/colors';

function hashCode(str = ''): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return Math.abs(hash);
}

/**
 * Given a company ticker, hash and return a uniform background color
 * in the HSL color space
 */
export default function getCompanyLogoBackground(ticker: string) {
  const [r, g, b] = hslToRgb((hashCode(ticker) % 360) / 360, 0.5, 0.5);
  return `${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}
