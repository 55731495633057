import styled, { keyframes, css } from 'styled-components';
import { media } from '@simplywallst/ui-core';

import { hexToRgbA } from '@/utilities/colors';

const SNACKBAR_Z_INDEX = 9998;

export interface SnackbarItemWrapperProps {
  height: number;
  lifeTime: number;
  transitionIn: boolean;
  transitionOut: boolean;
}

export interface SnackbarMessageProps {
  hasType: boolean;
}

export interface SnackbarItemWrapperStyle {
  display: string;
}

export interface SnackbarItemWrapperAttrs {
  style?: (props: SnackbarItemWrapperProps) => SnackbarItemWrapperStyle;
}

const show = (yPosition: number, safeArea: string) => keyframes`
  from        { transform: translate(0, calc(${yPosition}px + ${safeArea})); }
  to          { transform: translate(0, 0); }
`;

const hide = () => keyframes`
  from        { opacity: 1; }
  to          { opacity: 0; }
`;

const cubicBezier = `cubic-bezier(0.23, 1, 0.32, 1)`;

const transitionInAnimation = css<SnackbarItemWrapperProps>`
  animation: ${({ theme, height }) =>
      show(height + theme.x10spacePx, theme.safeAreaInsetBottom)}
    0.3s ${cubicBezier} 0s forwards;
  ${media.lgUp`
      animation: ${({ theme, height }) =>
        show(
          height + theme.x6spacePx,
          theme.safeAreaInsetBottom
        )} 0.3s ${cubicBezier} 0s forwards;
  `};
`;

const transitionOutAnimation = css<SnackbarItemWrapperProps>`
  animation: ${() => hide()} 0.2s ${cubicBezier}
    ${({ lifeTime }) => (lifeTime > 0 ? lifeTime / 1000 : 0)}s forwards;
  ${media.lgUp`
    animation: ${() => hide()} 0.2s ${cubicBezier}
      ${({ lifeTime }) => (lifeTime > 0 ? lifeTime / 1000 : 0)}s forwards;
  `};
`;

export const StyledSnackBarItem = styled('section')<SnackbarItemWrapperProps>`
  position: fixed;
  z-index: ${SNACKBAR_Z_INDEX};
  left: ${({ theme }) => theme.x2spacePx}px;
  right: ${({ theme }) => theme.x2spacePx}px;
  bottom: ${({ theme }) =>
    `calc(${theme.x10spacePx}px + ${theme.safeAreaInsetBottom})`};
  ${media.lgUp`
    width: 100%;
    max-width: 448px;
    left: auto;
    right: ${({ theme }) => theme.x4spacePx}px;
    bottom: ${({ theme }) => theme.x6spacePx}px;
  `};

  ${({ transitionIn }) => transitionIn && transitionInAnimation}
  ${({ transitionOut }) => transitionOut && transitionOutAnimation}

  box-shadow: ${({ theme }) =>
    `0px ${theme.x1spacePx}px 12px ${hexToRgbA(theme.color.black, 0.25)}`};
`;
