import styled from 'styled-components';
import { media } from '@simplywallst/ui-core';

interface Props {
  isSelected: boolean;
  isFocused: boolean;
  isDisabled: boolean;
}

export const StyledOption = styled('li')<Props>`
  outline: none;
  cursor: pointer;
  white-space: nowrap;

  padding: ${({ theme }) => theme.x1}px;
  background-color: ${({ theme, isSelected }) => {
    if (isSelected) return theme.color.brand01RGBA(0.1);
    return 'transparent';
  }};
  color: ${({ theme, isDisabled }) => {
    if (isDisabled) return theme.color.textSofter;
    return theme.color.black;
  }};
  ${media.lgUp`
    background-color: ${({ theme, isSelected, isFocused }) => {
      if (isSelected) return theme.color.brand01RGBA(0.1);
      if (isFocused) return theme.color.paper;
      return 'transparent';
    }};
    &:hover {
      background-color: ${({ theme }) => theme.color.paper};
    }
  `}
`;

export const StyledUl = styled('ul')`
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const Separator = styled('li')`
  border-top: 1px solid ${({ theme }) => theme.color.paper};
  margin: ${({ theme }) => theme.x1}px 0;
`;
