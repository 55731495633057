import type { ComponentProps } from 'react';
import Link from 'redux-first-router-link';
import styled from 'styled-components';

export interface RouterLinkAction {
  type: string;
  payload?: any;
}
export interface RouterLinkProps extends ComponentProps<'a'> {
  to?: string | RouterLinkAction;
  noUnderline?: boolean;
  inheritColor?: boolean;
  light?: boolean;
  small?: boolean;
  secondary?: boolean;
  styleType?: any;
  display?: any;
}

const RouterLinkComponent = ({
  children,
  inheritColor,
  light,
  noUnderline,
  secondary,
  small,
  styleType,
  display,
  ...rest
}: RouterLinkProps) => (
  // @ts-expect-error - FIXME
  <Link {...rest}>{children}</Link>
);

export const RouterLink = styled(RouterLinkComponent)`
  ${({ inheritColor }) => inheritColor && `color: inherit;`}
  ${({ noUnderline }) => noUnderline && `text-decoration: none;`}
`;
