import { getCSSVar } from '@simplywallst/ui-core/next';
import { useTranslation } from '@/hooks/useTranslation';
import { getStaticUrl } from '@/hooks/useCloudflareImage';
import { useCloudflareImage } from '@/hooks/useCloudflareImage';
import { SWSLogoIcon } from '@simplywallst/ui-core';

export const NarrativePanelRegister = () => {
  const t = useTranslation();

  const lightCard = useCloudflareImage({
    src: getStaticUrl('narratives/rego-modal-light.png'),
    width: 700,
  });
  const darkCard = useCloudflareImage({
    src: getStaticUrl('narratives/rego-modal-dark.png'),
    width: 700,
  });

  return (
    <div className="h-full bg-surface-1">
      <div className="grid h-full grid-rows-[max-content_1fr_max-content] place-items-center gap-x4 bg-gradient-to-b from-[#1C1D2B] to-[black] text-solid light:bg-none lg:gap-x2">
        <SWSLogoIcon className="mt-x2 h-5 w-auto text-solid lg:h-[30px]" />
        <div className="grid h-full place-content-center justify-center gap-x1 px-x2">
          <p className="m-0 text-pretty text-center text-lg font-medium text-solid">
            {t('app.modal.registration.narrative.heading')}
          </p>
          <p className="m-0 text-pretty text-center text-sm text-soft">
            {t('app.modal.registration.narrative.description')}
          </p>
        </div>
        <div className="grid h-full items-end">
          <img
            src={getCSSVar('--s-theme') === 'light' ? lightCard : darkCard}
            className="h-[170px] w-[283px] object-scale-down lg:h-[205px] lg:w-[345px]"
            alt={t('app.modal.registration.narrative.imageAlt')}
          />
        </div>
      </div>
    </div>
  );
};
