import { useFlag as useUnleashFlag, useVariant, useFlagsStatus, } from '@unleash/proxy-client-react';
import { useMemo } from 'react';
/**
 * Retrieves all info for a specific flag.
 *
 * @example
 * const flag = useFlag('mono-default-to-demo');
 * if (
 *  flag.ready &&
 *  flag.enabled ||
 *  flag.variant.name === 'do-the-thing'
 * ) {
 *  //...
 * }
 */
export const useFlag = (name) => {
    const enabled = useUnleashFlag(name);
    const variant = useVariant(name);
    const { flagsReady, flagsError } = useFlagsStatus();
    return useMemo(() => ({
        enabled,
        variant,
        ready: flagsReady,
        error: flagsError,
    }), [enabled, variant, flagsReady, flagsError]);
};
