import { gql } from '@/utilities/gql';
import { makeGqlQuery } from '@/utilities/gqlQuery';
import { NARRATIVE_CARD_FRAGMENT } from '../narrativeCardFragment';

const QUERY = gql(
  `
  query NarrativeCardNarrativeById($narrativeId: ID!) {
    narrative(ref: $narrativeId) {
      ...NarrativeCardFields
    }
  }
`,
  [NARRATIVE_CARD_FRAGMENT]
);

export const useNarrativeById = makeGqlQuery(QUERY, {
  tags: ['narrative'],
});
