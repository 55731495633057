import { usePathname } from '@/hooks/location/usePathname';
import { useEffect, useState } from 'react';

/**
 * If the user navigates to these paths and the modal is open,
 * we will automatically close the modal.
 */
const AUTO_DISMISS_PATHS = [
  /^\/welcome/,
  /^\/narratives\/new/,
  /^\/plans/,
  /^\/user/,
];

export const useAutoDismiss = (
  onClose: () => void,
  companyId: string | null
) => {
  const [dissmisedPath, setDissmisedPath] = useState<string | null>(null);
  const pathname = usePathname();

  const shouldDismiss = AUTO_DISMISS_PATHS.some(
    (path) => path.test(pathname) && dissmisedPath !== pathname
  );

  useEffect(() => {
    setDissmisedPath(null);
  }, [companyId]);

  useEffect(() => {
    if (!shouldDismiss || !companyId) return;

    onClose();
    setDissmisedPath(pathname);
  }, [shouldDismiss, companyId, onClose, pathname]);
};
