import SWSLogoBlack from '@assets/images/logo/sws-logo-black.svg';
import { useUtmExperiment } from '@/hooks/usePlanUtmExperiment';
import { LightPanelBackground } from '../PanelBackground';
import { useTranslation } from '@/hooks/useTranslation';

export const LightSplashPanelSignIn = () => {
  const { isUtmExperiment } = useUtmExperiment();
  const t = useTranslation();
  return (
    <div className="h-[304px] lg:h-full">
      <LightPanelBackground>
        <div className="grid h-full place-items-center p-4 pb-48 text-center">
          <div>
            <SWSLogoBlack height={32} />
            <p className="text-white m-0 mt-4 text-lg font-strong lg:mt-8">
              {isUtmExperiment
                ? t('app.modal.registration.signupWall.signIn.utmHeading')
                : t('app.modal.registration.signupWall.signIn.defaultHeading')}
            </p>
            <p className="mx-auto mt-4 hidden max-w-xl text-center text-xs text-p-white-170 lg:block ">
              {isUtmExperiment
                ? ''
                : t('app.modal.registration.signupWall.signIn.defaultDesc')}
            </p>
          </div>
        </div>
      </LightPanelBackground>
    </div>
  );
};
