import { useEffect, useState } from 'react';

import { useTheme, useIsMounted } from '@simplywallst/ui-core';

export const useMedia = (query: string, defaultMatches = false) => {
  const [state, setState] = useState(defaultMatches);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const mediaQueryList: MediaQueryList = window.matchMedia(query);

    const onChange = () => {
      if (!isMounted) {
        return;
      }
      setState(Boolean(mediaQueryList.matches));
    };

    if (typeof mediaQueryList.addEventListener === 'function') {
      mediaQueryList.addEventListener('change', onChange);
    } else {
      mediaQueryList.addListener(onChange);
    }

    setState(mediaQueryList.matches);

    return () => {
      if (typeof mediaQueryList.removeEventListener === 'function') {
        mediaQueryList.removeEventListener('change', onChange);
      } else {
        mediaQueryList.removeListener(onChange);
      }
    };
  }, [query, isMounted]);

  return state;
};

export const useIsMobileScreen = ({ defaultMatches }) => {
  const theme = useTheme();
  return useMedia(`(max-width: ${theme.mdMaxRaw}px)`, defaultMatches);
};

export const useIsSmallScreen = ({ defaultMatches }) => {
  const theme = useTheme();
  return useMedia(`(max-width: ${theme.smMaxRaw}px)`, defaultMatches);
};

export const useIsMediumScreen = ({ defaultMatches }) => {
  const theme = useTheme();
  return useMedia(
    `(min-width: ${theme.mdMinRaw}px) and (max-width: ${theme.mdMaxRaw}px)`,
    defaultMatches
  );
};
