import type { ResultOf } from '@/utilities/gql';
import { gql } from '@/utilities/gql';
import { makeGqlQuery } from '@/utilities/gqlQuery';

const QUERY = gql(`
  query UseMe {
    me {
      communityProfile {
        displayName
        publicId
        imageUrl
        bio
        links {
          label
          link
        }
      }
    }
  }
`);

export type Result = ResultOf<typeof QUERY>;
export const useMe = makeGqlQuery(QUERY, {
  staleTime: Infinity, // NOTE: Avoid overfetching
  tags: ['community-profile'],
});
