import { initGraphQLTada } from 'gql.tada';
import type { introspection } from '@simplywallst/graphql/schema';

export const gql = initGraphQLTada<{
  introspection: introspection;
  // Can be used to prevent gql.tada from enforcing fragment masking by default
  // https://gql-tada.0no.co/guides/fragment-colocation#fragment-masking
  disableMasking: false;
  scalars: {
    DateTime: string;
    JSON: Record<string, unknown> | Array<unknown>;
    JSONObject: Record<string, unknown>;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada';
export { readFragment } from 'gql.tada';
