import type { ReactNode } from 'react';
import { createContext, useContext, useRef } from 'react';

import { useMenuTrigger } from '@react-aria/menu';

import { useOverlayTriggerState } from '@react-stately/overlays';

import { useOverlayTrigger } from '@react-aria/overlays';

import type { OverlayTriggerProps } from '@react-stately/overlays';

interface Context {
  triggerRef: React.MutableRefObject<HTMLButtonElement | null>;
  overlayState: ReturnType<typeof useOverlayTriggerState>;
  triggerProps: ReturnType<typeof useOverlayTrigger>['triggerProps'];
  overlayProps: ReturnType<typeof useOverlayTrigger>['overlayProps'];
  menuProps: ReturnType<typeof useMenuTrigger>['menuProps'];
}

export const MenuContext = createContext<Context | undefined>(undefined);

interface Props
  extends Pick<OverlayTriggerProps, 'defaultOpen' | 'onOpenChange'> {
  defaultOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  children: ReactNode;
}

export const MenuProvider = ({
  defaultOpen,
  onOpenChange,
  children,
}: Props) => {
  const triggerRef = useRef<HTMLButtonElement>(null);

  const overlayState = useOverlayTriggerState({
    defaultOpen,
    onOpenChange,
  });

  const { menuProps } = useMenuTrigger(
    {},
    { ...overlayState, focusStrategy: 'first' },
    triggerRef
  );

  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: 'menu' },
    overlayState,
    triggerRef
  );

  return (
    <MenuContext.Provider
      value={{
        menuProps,
        triggerRef,
        overlayState,
        triggerProps,
        overlayProps,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (typeof context === 'undefined') {
    throw new Error(
      'useMenuContext: Attempting to access undefined context, possibly outside of `MenuProvider`'
    );
  }
  return context;
};
