import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FastCommentsCountBaseUrl } from '../constants';

export const useFastCommentsCount = (commentKey: string) => {
  const { data } = useQuery({
    queryKey: ['FAST_COMMENTS_COUNT', commentKey],
    queryFn: async () => {
      const fastCommentsCountURL = `${FastCommentsCountBaseUrl}${commentKey}`;
      const response = await fetch(fastCommentsCountURL);
      return await response.json();
    },
    staleTime: 3600000,
  });
  const queryClient = useQueryClient();
  const setCommentCount = useCallback(
    (count: number) => {
      const queryKey = ['FAST_COMMENTS_COUNT', commentKey];
      queryClient.setQueryData(queryKey, ({ ...rest }) => ({
        ...rest,
        count,
      }));
    },
    [commentKey, queryClient]
  );
  return {
    commentCount: data?.count ?? 0,
    setCommentCount,
  };
};
