import { KeyMetric } from '@/components/KeyMetric';

type Props = {
  showCompanyIcon?: boolean;
};

export const LoadingState = ({ showCompanyIcon }: Props) => {
  return (
    <div
      className="overflow-hidden rounded-x1"
      role="progressbar"
      aria-label="Loading Narrative Card"
      aria-busy="true"
    >
      <div className="relative grid gap-x2 bg-surface-3 p-x2">
        <div className="bones absolute right-x1_5 top-x1_5 h-x4 w-x5 rounded-x0_75" />
        {showCompanyIcon && (
          <div className="grid grid-cols-[max-content_auto] items-center gap-x1">
            <div className="bones size-5 rounded-x1" />
            <div className="bones h-x1 w-x7" />
          </div>
        )}

        <div className="grid grid-cols-[max-content_max-content] items-center gap-x1">
          <div className="bones size-x4 rounded-x2" />
          <div className="grid grid-rows-[max-content_max-content] gap-x1">
            <div className="bones h-x3 min-w-36" />
            <div className="bones h-x1_5 max-w-[60%]" />
          </div>
        </div>

        <div className="grid grid-rows-[max-content_max-content] gap-x0_75">
          <div className="bones h-x3" />
          <div className="bones h-x3" />
          <div className="bones mt-x0_75 h-x2 max-w-[80%]" />
          <div className="bones h-x2 max-w-[60%]" />
        </div>
      </div>
      <div className="grid gap-x2 bg-surface-2 p-x2">
        <div className="grid grid-cols-2 gap-x2">
          <KeyMetric color="transparentSubtle">
            <div className="grid grid-rows-[max-content_max-content] gap-x1">
              <div className="bones h-x3 min-w-x13" />
              <div className="bones h-x1_75 w-x9" />
            </div>
          </KeyMetric>
          <KeyMetric color="transparentSubtle">
            <div className="grid grid-rows-[max-content_max-content] gap-x1">
              <div className="bones h-x3 min-w-x13" />
              <div className="bones h-x1_75 w-x9" />
            </div>
          </KeyMetric>
        </div>
        <div className="bones h-x5" />
        <div className="grid grid-cols-2 justify-between gap-x2">
          <div className="grid grid-cols-[repeat(3,max-content)] justify-start gap-x2">
            <div className="grid grid-cols-[repeat(2,min-content)] place-content-center gap-x0_5">
              <div className="bones h-x3 w-x4" />
            </div>
            <div className="grid grid-cols-[repeat(2,min-content)] place-content-center gap-x0_5">
              <div className="bones h-x3 w-x4" />
            </div>
            <div className="grid grid-cols-[repeat(2,min-content)] place-content-center gap-x0_5">
              <div className="bones h-x3 w-x4" />
            </div>
          </div>
          <div className="grid grid-cols-[max-content] justify-end gap-x2">
            <div className="bones h-x3 min-w-x8" />
          </div>
        </div>
      </div>
    </div>
  );
};
