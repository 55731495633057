import type { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTheme } from '@simplywallst/ui-core';
import { useLocale } from '@/hooks/useLocale';

interface Props {
  title: null | string;
  titleSuffix?: string;
  description?: string;
  allowCrawlers?: boolean;
  allowFollow?: boolean;
  children?: ReactNode;
  openGraphImageUrl?: string;
}

function getLanguageTerritoryFormat(locale: ReturnType<typeof useLocale>) {
  switch (locale) {
    case 'en':
      return 'en_US';
    case 'es':
      return 'es_ES';
    case 'de':
      return 'de_DE';
  }
}

const DocumentHeader = ({
  allowCrawlers,
  allowFollow,
  title,
  titleSuffix,
  description,
  children,
  openGraphImageUrl,
}: Props) => {
  const theme = useTheme();
  const getRobotsContent = () => {
    if (allowCrawlers) return 'INDEX, FOLLOW';
    if (allowFollow) return 'NOINDEX, FOLLOW';
    return 'NOINDEX, NOFOLLOW';
  };
  const locale = useLocale();
  const coalescedTitle = `${title || ''}${titleSuffix || ''}`;

  return (
    <Helmet>
      <title>{coalescedTitle}</title>
      <meta property="title" content={coalescedTitle} />
      <meta property="og:title" content={coalescedTitle} />
      <meta property="og:locale" content={getLanguageTerritoryFormat(locale)} />
      <meta name="ROBOTS" content={getRobotsContent()} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Simply Wall St" />
      <meta content="Simply Wall St" itemProp="name" />
      <meta name="theme-color" content={theme.color.spaceGray} />
      {description && <meta name="description" content={description} />}
      {description && <meta name="og:description" content={description} />}
      {openGraphImageUrl && (
        <meta name="og:image" content={openGraphImageUrl} />
      )}
      {children}
    </Helmet>
  );
};

DocumentHeader.defaultProps = {
  titleSuffix: ' - Simply Wall St',
  allowCrawlers: false,
};

export default DocumentHeader;
