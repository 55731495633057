import { useUserAgent } from '@simplywallst/hooks/useUserAgent';
import { useIsMobileScreen } from '@/hooks/useMedia';
import {
  getCloudflareImageUrl,
  getStaticUrl,
} from '@/hooks/useCloudflareImage';

export const BACKGROUND_IMAGE = 'signup/light-signup-wall-bg.png';
export const MOBILE_DIMENSIONS = { width: 384, height: 174 };
export const DESKTOP_DIMENSIONS = { width: 512, height: 210 };

interface Props {
  children: React.ReactNode;
}

export const LightPanelBackground = ({ children }: Props) => {
  const { isMobile } = useUserAgent();
  const isMobileScreen = useIsMobileScreen({ defaultMatches: isMobile });

  const dimensions = isMobileScreen ? MOBILE_DIMENSIONS : DESKTOP_DIMENSIONS;

  const backgroundUrl = getCloudflareImageUrl({
    src: getStaticUrl(BACKGROUND_IMAGE),
    width: dimensions.width * 2,
    height: dimensions.height * 2,
  });

  return (
    <div
      className="h-full rounded-t-x0_75 bg-bottom bg-no-repeat lg:rounded-bl-x0_75 lg:rounded-tr-x0"
      style={{
        backgroundImage: `url(${backgroundUrl}), linear-gradient(180deg, #D8EDFF 36.5%, #CBE2F7 100%)`,
        backgroundSize: '100% auto, cover',
      }}
    >
      {children}
    </div>
  );
};
