import type { TreeState } from '@react-stately/tree';

import type { Item } from '@react-stately/collections';

import { useMenuSection } from '@react-aria/menu';

import { MenuItem } from './MenuItem';

import { Separator, StyledUl } from './styled';

type Item = NonNullable<ReturnType<TreeState<object>['collection']['getItem']>>;

interface Props<T> {
  item: Item;
  state: TreeState<T>;
}

export const ListSection = <T extends object>({ item, state }: Props<T>) => {
  const { itemProps, groupProps } = useMenuSection({
    heading: item.rendered,
    'aria-label': item['aria-label'],
  });

  if (item.hasChildNodes === false) {
    return item.key !== state.collection.getFirstKey() ? (
      <Separator role="presentation" />
    ) : null;
  }

  return (
    <>
      {item.key !== state.collection.getFirstKey() && (
        <Separator role="presentation" />
      )}
      <li {...itemProps}>
        <StyledUl {...groupProps}>
          {[...item.childNodes].map((node) => (
            <MenuItem key={node.key} item={node} state={state} />
          ))}
        </StyledUl>
      </li>
    </>
  );
};
