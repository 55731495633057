import type { ReactNode } from 'react';
import { Badge } from '@simplywallst/ui-core/next';
import ChevronRightSmallSolid from '@simplywallst/ui-core/icons/ChevronRightSmallSolid';

type Props = {
  children: ReactNode;
};

export const NarrativeCardExcerpt = ({ children }: Props) => {
  return (
    <div className="mt-2">
      <div className="line-clamp-2 text-ellipsis bg-gradient-to-b from-p-white-150 to-transparent bg-clip-text text-xs text-transparent no-underline [word-break:break-word] hover:no-underline focus:no-underline">
        {children}
      </div>
      <span className="flex items-center justify-center">
        <Badge className="absolute bottom-[19px] flex items-center px-4 py-0 text-p-white">
          View narrative
          <ChevronRightSmallSolid className="fill-soft" />
        </Badge>
      </span>
    </div>
  );
};
