import { useTranslation } from '@/hooks/useTranslation';
import SWSLogoBlack from '@assets/images/logo/sws-logo-black.svg';
import { LightPanelBackground } from '../PanelBackground';

export const LightSplashPanelRegister = () => {
  const t = useTranslation();

  return (
    <div className="h-[304px] lg:h-full">
      <LightPanelBackground>
        <div className="p-4 text-center">
          <SWSLogoBlack height={32} />
          <p className="text-white m-0 mt-4 text-lg font-strong">
            {t('app.modal.registration.signupWall.splash.heading')}
          </p>

          <p className="mx-auto mt-2 max-w-xl text-center text-xs text-p-white-170">
            {t('app.modal.registration.signupWall.splash.description')}
          </p>
        </div>
      </LightPanelBackground>
    </div>
  );
};
