import { useEffect, useRef } from 'react';

import type { useTreeState } from '@react-stately/tree';

import { useMenuItem } from '@react-aria/menu';

import { StyledOption } from './styled';

type TreeState = ReturnType<typeof useTreeState>;
type Collection = TreeState['collection'];

interface Props<T extends object> {
  item: NonNullable<ReturnType<Collection['getItem']>>;
  state: ReturnType<typeof useTreeState<T>>;
}

export const MenuItem = <T extends object>({ item, state }: Props<T>) => {
  const ref = useRef<HTMLLIElement>(null);

  const { menuItemProps, isSelected, isDisabled, isFocused } = useMenuItem(
    { key: item.key },
    state,
    ref
  );

  /*
   * Prevent the default touchend behavior on the menu item from triggering the
   * clicks on elements outside of the menu scope (below the popover)
   */
  useEffect(() => {
    const handleTouchEnd = (e: TouchEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const element = ref.current;
    if (element !== null) {
      element.addEventListener('touchend', handleTouchEnd);
    }
  }, []);

  return (
    <StyledOption
      {...menuItemProps}
      isSelected={isSelected}
      isDisabled={isDisabled}
      isFocused={isFocused}
      ref={ref}
    >
      {item.rendered}
    </StyledOption>
  );
};
