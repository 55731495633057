import { useNarrativeNotFoundReason } from '../hooks/useNarrativeNotFoundReason';
import { useNarrativeNotFoundNarratives } from '../hooks/useNarrativeNotFoundNarratives';
import { NarrativeNotFoundSkeleton } from './NarrativeNotFoundSkeleton';
import { Button } from '@simplywallst/ui-core/next';
import { NarrativeCardPopulated } from '@/components/NarrativeCard';
import { NarrativeNotFoundLayout } from './NarrativeNotFoundLayout';
import { useFeedTypeAndNarratives } from '../hooks/useFeedTypeAndNarratives';

export const NarrativeNotFoundMain = () => {
  const {
    companyCanonicalUrl,
    feedType: errorFeedType,
    isLoading,
  } = useNarrativeNotFoundReason();
  const { data } = useNarrativeNotFoundNarratives(
    {
      canonicalUrl: companyCanonicalUrl,
      hasCanonicalUrl: !!companyCanonicalUrl,
    },
    {
      retry: false,
    }
  );

  const { feedType, narratives } = useFeedTypeAndNarratives({
    errorFeedType,
    data,
  });

  if (isLoading) return <NarrativeNotFoundSkeleton />;
  if (!data) return null;

  return (
    <>
      <div className="my-x3 grid grid-cols-[max-content_max-content] gap-x1">
        <Button
          href={
            feedType === 'stock' && companyCanonicalUrl
              ? companyCanonicalUrl.replace('/stocks', '/community/narratives')
              : '/community/narratives'
          }
          variant="primary"
        >
          Browse Community
        </Button>
        <Button href="/dashboard" variant="secondary">
          Dashboard
        </Button>
      </div>
      {feedType === 'weeklyPicks' && (
        <h2 className="m-0 text-center text-sm font-normal text-solid">
          Weekly Picks
        </h2>
      )}
      <NarrativeNotFoundLayout>
        {narratives.map((narrative) => (
          <NarrativeCardPopulated
            key={narrative.id}
            narrative={narrative}
            showCompanyIcon
          />
        ))}
      </NarrativeNotFoundLayout>
    </>
  );
};
