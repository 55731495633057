import { useTranslation } from '@/hooks/useTranslation';
import CrossSolid from '@simplywallst/ui-core/icons/CrossSolid';
import { cn } from '@simplywallst/ui-core/next';

interface Props {
  onClick: () => void;
  className?: string;
}

export const CloseIcon = ({ onClick, className }: Props) => {
  const t = useTranslation();

  return (
    <button
      onClick={onClick}
      data-cy-id="close-button"
      aria-label={t('app.modal.registration.narrative.close')}
      className={cn(
        'focus-outline absolute right-2 top-2 z-1 cursor-pointer rounded-md',
        className
      )}
    >
      <CrossSolid className="fill-current" />
    </button>
  );
};
