import { Item, Menu } from '@/components/Menu';
import { Button } from '@simplywallst/ui-core/next';
import MoreHorizontalSolid from '@simplywallst/ui-core/icons/MoreHorizontalSolid';
import DangerSolid from '@simplywallst/ui-core/icons/DangerSolid';
import BinSolid from '@simplywallst/ui-core/icons/BinSolid';
import { useState, type ReactNode } from 'react';
import { NarrativeDeleteConfirmation } from '../NarrativeDeleteConfirmation';
import { useFlag } from '@simplywallst/unleash';
import { useReportNarrative } from '@/pages/Narrative/hooks/useReportNarrative';
import { useMe } from '@/components/User/components/Profile/components/CommunityProfile/hooks/useMe';
import useUser from '@/hooks/useUser';

type Props = {
  narrativeId: string;
  publicId?: string;
  canonicalUrl?: string;
  valuationType: 'DCF' | 'ANALYSTS' | 'USER_DEFINED';
};

export const NarrativeCardDropdown = ({
  narrativeId,
  publicId,
  canonicalUrl,
  valuationType,
}: Props) => {
  const { data: user, queryStatus: useUserStatus } = useUser();
  const { data: me, status: useMeStatus } = useMe(
    {},
    { enabled: user?.loggedIn }
  );

  const { reportNarrativeWithTypeformDrawer } = useReportNarrative({
    narrativeId,
    canonicalUrl: canonicalUrl ?? '',
    narrativeType: valuationType,
  });

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);

  const handleSelection = (value: string) => {
    if (value === 'REPORT_NARRATIVE') {
      reportNarrativeWithTypeformDrawer();
    } else if (value === 'DELETE_NARRATIVE') {
      setIsDeleteConfirmationOpen(true);
    }
  };

  const { enabled: isDeleteNarrativeEnabled } = useFlag(
    'mono-narrative-delete'
  );

  if (useUserStatus === 'pending' || useMeStatus === 'pending') return null;

  const isLoggedIn = user && user?.loggedIn;
  const isAuthor =
    isLoggedIn && me?.me?.communityProfile?.publicId === publicId;

  const options = [
    isLoggedIn &&
      !isAuthor && {
        label: (
          <div
            data-theme="light"
            className="grid grid-cols-[16px_max-content] items-center gap-1 text-solid"
          >
            <DangerSolid className="h-x2 fill-current" />
            <span className="text-xs">Report</span>
          </div>
        ),
        value: 'REPORT_NARRATIVE',
      },
    isLoggedIn &&
      isAuthor &&
      isDeleteNarrativeEnabled && {
        label: (
          <div
            data-theme="light"
            className="grid grid-cols-[16px_max-content] items-center gap-1 text-solid"
          >
            <BinSolid className="h-x2 fill-current" />
            <span className="text-xs">Delete</span>
          </div>
        ),
        value: 'DELETE_NARRATIVE',
      },
  ].filter(Boolean) as { label: ReactNode; value: string }[];

  if (!options.length) return null;

  return (
    <>
      <Menu
        label={
          <Button aria-label="More options" variant="subtle">
            <MoreHorizontalSolid />
          </Button>
        }
        selectionMode="none"
        onAction={(key) => handleSelection(String(key))}
      >
        {options.map(({ label, value }) => (
          <Item key={value} textValue={value}>
            {label}
          </Item>
        ))}
      </Menu>
      {isDeleteNarrativeEnabled && (
        <NarrativeDeleteConfirmation
          narrativeId={narrativeId}
          isDeleteConfirmationOpen={isDeleteConfirmationOpen}
          setIsDeleteConfirmationOpen={setIsDeleteConfirmationOpen}
        />
      )}
    </>
  );
};
