import { useParams } from '@/hooks/location/useParams';
import { gql } from '@/utilities/gql';
import { makeGqlQuery } from '@/utilities/gqlQuery';
import { ERROR_CODES_TO_FEED_TYPE } from '../utils';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useTracking } from 'react-tracking';
import { getNarrativeErrorCode } from '@/pages/Narrative/utils/error';

const QUERY = gql(`
  query NarrativeNotFoundReason($ref: ID!) {
    narrative(ref: $ref) {
      id
    }
  }
`);

const useNarrativeNotFoundReasonBase = makeGqlQuery(QUERY, {
  staleTime: 0,
  tags: ['narrative'],
});

type ParamTypes = {
  companyname?: string;
  country?: string;
  exchange_ticker?: string;
  industry?: string;
  slug?: string;
  id?: string;
};

const generateCompanyCanonicalUrl = ({
  country,
  industry,
  exchange_ticker,
  companyname,
}: Pick<
  ParamTypes,
  'country' | 'industry' | 'exchange_ticker' | 'companyname'
>) => {
  if (!country || !industry || !exchange_ticker || !companyname) {
    return null;
  }

  return `/stocks/${country}/${industry}/${exchange_ticker}/${companyname}`;
};

export const useNarrativeNotFoundReason = () => {
  const params = useParams<ParamTypes>();
  const companyCanonicalUrl = useMemo(
    () => generateCompanyCanonicalUrl(params),
    [params]
  );

  const ref = params.id || params.slug;

  const { error, isLoading } = useNarrativeNotFoundReasonBase(
    {
      ref: ref!,
    },
    {
      retry: false,
      enabled: !!ref,
    }
  );
  const { trackEvent } = useTracking();

  const errorCode = getNarrativeErrorCode(error);
  const feedType = ERROR_CODES_TO_FEED_TYPE[errorCode];

  useEffect(() => {
    if (isLoading) return;

    trackEvent({
      page: 'narrative',
      subject: 'not-found',
      action: 'landed',
      type: 'track',
      errorCode: errorCode,
    });
  }, [errorCode, isLoading, trackEvent]);

  return {
    isLoading,
    companyCanonicalUrl,
    feedType:
      feedType === 'stock' && companyCanonicalUrl
        ? ('stock' as const)
        : ('weeklyPicks' as const),
  };
};
