import type { FragmentOf } from '@/utilities/gql';
import { gql } from '@/utilities/gql';

export const NARRATIVE_URL_COMPANY_FRAGMENT = gql(`
  fragment NarrativeUrlCompanyFields on Company @_unmask {
    canonicalURL
  }
`);

type Props = {
  narrativeSlug: string;
  canonicalURL: FragmentOf<
    typeof NARRATIVE_URL_COMPANY_FRAGMENT
  >['canonicalURL'];
};

export const getCompanyUrlSegments = (
  canonicalURL: FragmentOf<
    typeof NARRATIVE_URL_COMPANY_FRAGMENT
  >['canonicalURL']
) => {
  const defaultSegments = {
    country: '',
    industry: '',
    exchangeTicker: '',
    companyName: '',
  };

  if (!canonicalURL) {
    return defaultSegments;
  }

  const segments = String(canonicalURL).split('/').slice(2);

  if (segments.length < 4) {
    return defaultSegments;
  }

  const countryIndex = 0;
  const industryIndex = 1;
  const exchangeTickerIndex = 2;
  const companyNameIndex = 3;

  return {
    country: segments[countryIndex],
    industry: segments[industryIndex],
    exchangeTicker: segments[exchangeTickerIndex],
    companyName: segments[companyNameIndex],
  };
};

export const getNarrativeUrl = ({ canonicalURL, narrativeSlug }: Props) => {
  const BASE_URL = '/community/narratives';

  if (!canonicalURL) {
    return `${BASE_URL}`;
  }

  const companyCanonicalUrl = String(canonicalURL).split('/');

  const segments = [
    '',
    'community',
    'narratives',
    ...companyCanonicalUrl.slice(2),
  ];

  if (narrativeSlug) {
    segments.push(narrativeSlug);
  }

  const url = segments.join('/');

  return url;
};
