import { toTruncated } from '@/utilities/formatting';

type Props = {
  /**
   * The discount percentage to display
   * e.g
   * 1 = 100%
   * -0.9 = -90%
   * 1000 = 100k%
   * null = n/a
   */
  discount: number | null;
};

const formatDiscount = (discount: number) => {
  const discountAbs = Math.abs(discount) * 100;

  return `${toTruncated(discountAbs, 1)}%`;
};

export const CompanyIntrinsicDiscountBase = ({ discount }: Props) => {
  if (typeof discount !== 'number')
    return (
      <span className="text-softer">
        n/a<span className="sr-only">intrinsic discount</span>
      </span>
    );

  return (
    <span className="grid grid-cols-[auto] items-center gap-0.5">
      {discount <= 0 ? (
        <span className="truncate text-bad">
          {formatDiscount(discount)} overvalued
        </span>
      ) : (
        <span className="truncate text-good">
          {formatDiscount(discount)} undervalued
        </span>
      )}
      <span className="sr-only"> intrinsic discount</span>
    </span>
  );
};
