import loadable from '@loadable/component';

import { LoadingState } from './LoadingState';
import { useNarrativeById } from './hooks/useNarrativeById';
import type { PopulatedStateBaseProps } from './PopulatedState';

const ErrorState = loadable(() => import('./ErrorState'), {
  resolveComponent: (c) => c.ErrorState,
  fallback: <LoadingState />,
});
const PopulatedState = loadable(() => import('./PopulatedState'), {
  resolveComponent: (c) => c.PopulatedState,
  fallback: <LoadingState />,
});
const EmptyState = loadable(() => import('./EmptyState'), {
  resolveComponent: (c) => c.EmptyState,
  fallback: <LoadingState />,
});

type Props = PopulatedStateBaseProps & {
  narrativeId: string;
};

export const NarrativeCard = ({ narrativeId, ...props }: Props) => {
  const { data, status } = useNarrativeById({ narrativeId });
  const narrative = data?.narrative;

  if (status === 'pending') {
    return <LoadingState />;
  }

  if (status === 'error') {
    return <ErrorState />;
  }

  if (!narrative) {
    return <EmptyState />;
  }

  return <PopulatedState {...props} narrative={narrative} />;
};
