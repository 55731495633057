import { NarrativeCardLoading } from '@/components/NarrativeCard';
import { NarrativeNotFoundLayout } from './NarrativeNotFoundLayout';

export const NarrativeNotFoundSkeleton = () => {
  return (
    <>
      <div className="my-x3 grid grid-cols-[max-content_max-content] gap-x1">
        <div className="bones h-x5 w-[140px]" />
        <div className="bones h-x5 w-[100px]" />
      </div>
      <NarrativeNotFoundLayout
        role="presentation"
        aria-label="Loading skeleton"
      >
        {Array.from({ length: 3 }, (_, index) => (
          <NarrativeCardLoading showCompanyIcon key={index} />
        ))}
      </NarrativeNotFoundLayout>
    </>
  );
};
