import { NARRATIVE_CARD_FRAGMENT } from '@/components/NarrativeCard';
import { gql } from '@/utilities/gql';
import { makeGqlSuspenseQuery } from '@/utilities/gqlQuery';

const QUERY = gql(
  `
  query NarrativeNotFoundNarratives($canonicalUrl: String, $hasCanonicalUrl: Boolean!) {
    narrativeWeeklyPicks {
      ...NarrativeCardFields
    }
    narrativesByCanonicalUrl: narratives(first: 6, filter: { canonicalUrl: $canonicalUrl }, sort: { field: POPULARITY, direction: DESC }) @include(if: $hasCanonicalUrl) {
      edges {
        node {
          ...NarrativeCardFields
        }
      }
    }
    narratives(first: 6, sort: { field: POPULARITY, direction: DESC }) {
      edges {
        node {
          ...NarrativeCardFields
        }
      }
    }
  }
`,
  [NARRATIVE_CARD_FRAGMENT]
);

export const useNarrativeNotFoundNarratives = makeGqlSuspenseQuery(QUERY, {
  tags: ['narrative', 'narrative-weekly-picks', 'narrative-not-found'],
  staleTime: 3600000,
});
