import type { QueryTag } from './types';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

type InvalidateFn = (queryTags: QueryTag[]) => boolean;

export const useGqlQueryInvalidate = () => {
  const queryClient = useQueryClient();

  /**
   * Invalidate queries based on the provided tags (if some are found in the target query) or predicate function.
   */
  return useCallback(
    (tagsOrFn: QueryTag[] | InvalidateFn) =>
      queryClient.invalidateQueries({
        predicate: (query) => {
          const queryTags = query.meta?.tags;
          if (!Array.isArray(queryTags)) return false;

          if (typeof tagsOrFn === 'function') {
            return tagsOrFn(queryTags);
          }

          return tagsOrFn.some((tag) => queryTags.includes(tag));
        },
      }),
    [queryClient]
  );
};
