import { isNil } from '@/utilities/format';
import { CompanyIntrinsicDiscountBase } from './CompanyIntrinsicDiscountBase';

interface Props {
  lastSharePrice: number | null;
  npvPerShare: number | null;
  fairValue?: number | null;
}

export const calculateIntrinsicDiscount = (
  sharePrice: number | null,
  npvPerShare: number | null,
  companyFairValue?: number | null
) => {
  const fairValue = companyFairValue ?? npvPerShare;
  if (isNil(sharePrice) || isNil(fairValue) || fairValue === 0) {
    return null;
  }

  return (fairValue - sharePrice) / fairValue;
};

export const CompanyIntrinsicDiscount = ({
  npvPerShare,
  lastSharePrice,
  fairValue,
}: Props) => {
  const discount = calculateIntrinsicDiscount(
    lastSharePrice,
    npvPerShare,
    fairValue
  );

  return <CompanyIntrinsicDiscountBase discount={discount} />;
};
