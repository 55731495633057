import { useCallback } from 'react';

import useUser from './useUser';
import { useTypeform } from './useTypeform';

export type TypeformId =
  | 'bxeqC9jR' // Simply Wall St Sector/Industry Trends Feedback
  | 'NlCweWZh' // Market Valuation and Performance Chart
  | 'RveL1l' // Dashboard Feed Updates - Feedback
  | 'wvVZB6pM' // Discover Page feedback form
  | 'Ll9OXh' // Simply Wall St Analysis Feedback (React)
  | 'Hk37XBaI' // Portfolio Linking Survey
  | 'mPbFyoUR' // Investment thesis feedback
  | 'p9v7rfn8' // Vs Peers chart - Suggested Peers
  | 'Re3NqTzK' // new Portfolio linking experience feedback
  | 'c48uLkmB' // Manual portfolio experience feedback
  | 'g6BdSIhV' // Transaction portfolio experience feedback
  | 'AnZWHEfM' // Broker request (new Portfolio)
  | 'a1N1rZDc' // Churn cancellation survey
  | 'fMCYaRxQ' // Churn cancellation survey experiment
  | 'DSd3LSrh' // Pro API feedback
  | 'nfp2jrdA' // Note reminder feedback
  | 'LtbY09Hp' //  Revenue & Expenses Breakdown chart feedback
  | 'CQ5uBt42' //  Narrative feedback
  | 'EUG8bzuz' // AI Stock screener
  | 'suwuMcHR' // Create Narrative feedback
  | 'sGGGNAGE' // Screener feedback
  | 'UjJqoRzy' // localisation feedback
  | 'hhQKsh1e' // localisation feedback (spanish)
  | 'G5mWOwKv' // localisation feedback (german)
  | 'a3enSUNp' // localisation feedback (japanese)
  | 'jK89Qvjn' // localisation feedback (korean)
  | 'JtFZpox8' // localisation feedback (french)
  | 'wvTU4SIp' // localisation feedback (italian)
  | 'ezCaLiPl' // localisation feedback (dutch)
  | 'WrCw4CIG' // localisation feedback (turkish)
  | 'nD7fZYVq' // localisation feedback (swedish)
  | 'RpQD7Uxc' // Narrative creation feedback
  | 'ToKQyRBU' // Holding Input Type feedback
  | 'QcdSFNqs' // Report narrative
  | 'lLagInU1' // Narratives on watchlist
  | 'IMPJMsax' // Watchlist limits
  | 'ayijp5kv' // Mega table beta version feedback
  | 'Cxh0faED'; // Report Community Profile

export function useTypeformDrawer<T>(
  formId: TypeformId,
  query?: T
): () => Promise<void> {
  const { data } = useUser();
  const getTypeform = useTypeform();

  const params = new URLSearchParams();

  if (query) {
    for (const [param, value] of Object.entries(query)) {
      params.set(param, String(value));
    }
  }

  params.set('userid', data.id);

  const url = `https://investor-research.typeform.com/to/${formId}#${params}`;

  return useCallback(async () => {
    if (RUNTIME_ENV !== 'server') {
      try {
        const typeform = await getTypeform();
        typeform?.makePopup(url, {
          mode: 'drawer_right',
          autoOpen: true,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [url, getTypeform]);
}
