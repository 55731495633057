import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';

const MUTATION = gql(`
  mutation NarrativeSetPrimary($narrativeId: ID!) {
    setPrimaryNarrative(narrativeId: $narrativeId) {
      id
      isPrimary
    }
  }
`);

export const useNarrativeSetPrimary = makeGqlMutation(MUTATION);
