import { getPath } from '@/router/utils';
import { forwardRef } from 'react';
import { push } from 'redux-first-router';
import type { LinkProps } from 'redux-first-router-link';
import Link from 'redux-first-router-link';

export interface RouterLinkAction {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

type Props = {
  to: string | RouterLinkAction;
} & LinkProps;

const getPathSafe: typeof getPath = (to) => {
  try {
    return getPath(to);
  } catch (e) {
    console.error('Invalid path', e, to);
    return typeof to === 'string' ? to : '';
  }
};

export const RouterLinkOptimised = forwardRef<Link | null, Props>(
  ({ to, onClick, ...rest }, ref) => {
    const pathname =
      typeof to === 'string'
        ? to
        : getPathSafe({
            ...to,
            payload: to.payload ?? {},
          });
    return (
      <Link
        ref={ref}
        to={pathname}
        onClick={(e) => {
          e.preventDefault();
          onClick?.(e);
          setTimeout(() => push(pathname));
        }}
        {...rest}
      />
    );
  }
);

RouterLinkOptimised.displayName = 'RouterLinkOptimised';
