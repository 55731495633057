import { useMutation, type UseMutationOptions } from '@tanstack/react-query';
import type { TadaDocumentNode } from 'gql.tada';
import { gqlClient } from '../graphQLClient';

/**
 * Helper wrapper to `react-query` mutation hook.
 * Allows typesafe creation of a gql mutation that has the correct mutation fn types and easy gql
 * client implementation.
 *
 * @example
 * const MUTATION = gql(`
 *    mutation deleteNote($id: String!) {
 *       deleteNote(id: $id)
 *    }
 * `);
 *
 * const useDeleteNoteMutation = makeGqlMutation(MUTATION)
 *
 * const SomeComponent = () => {
 *    const { mutate } = useDeleteNoteMutation({
 *      onSuccess(data, variables) {
 *        data.deleteNote;
 *        variables;
 *      },
 *    });
 *    ...
 *    mutate({ id: '1234' })
 * }
 *
 * @example extra options
 *
 * const SomeComponent = () => {
 *    const { mutate } = useDeleteNoteMutation();
 *    ...
 *    mutate({ id: '1234' })
 * }
 */
export const makeGqlMutation = <
  Query extends TadaDocumentNode,
  Result = Query extends TadaDocumentNode<infer R> ? R : never,
  Variables = Query extends TadaDocumentNode<Result, infer V> ? V : never,
>(
  query: TadaDocumentNode<Result, Variables>
) => {
  const useMutationHook = (
    options?: Omit<
      UseMutationOptions<
        Awaited<ReturnType<typeof gqlClient.request<Query, Result, Variables>>>,
        unknown,
        Variables
      >,
      'mutationFn'
    >
  ) =>
    useMutation({
      mutationFn: (variables: Variables) =>
        gqlClient.request<Query, Result, Variables>(query, variables),
      ...((options as unknown) ?? {}),
    });

  return useMutationHook;
};
