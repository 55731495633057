import type { RouterLinkAction } from '@/utilities/redux-first-router.export';
import { RouterLink } from '@/utilities/redux-first-router.export';
import { KeyMetric } from '@/components/KeyMetric';
import {
  NarrativeAuthorBase,
  getNarrativeAuthorRole,
} from '@/components/NarrativeAuthor';
import { toCurrencyTruncated, toTruncated } from '@/utilities/formatting';
import PriceTargetOutline from '@simplywallst/ui-core/icons/PriceTargetOutline';
import WarrenSolid from '@simplywallst/ui-core/icons/WarrenSolid';
import { CompanyIntrinsicDiscountBase } from '@/components/CompanyIntrinsicDiscount';
import { Heading, cn } from '@simplywallst/ui-core/next';
import { NarrativeCardDropdown } from '../NarrativeCardDropdown';
import { RouterLinkOptimised } from '@/components/RouterLinkOptimised';
import { ROUTE_NARRATIVE_COMMUNITY } from '@/constants/routes';
import { CompanyIcon } from '@/components/CompanyIcon';
import { useTracking } from 'react-tracking';
import { getCompanyUrlSegments } from '@/pages/Narrative/utils/getNarrativeUrl';
import type { FragmentOf } from '@/utilities/gql';
import { getTradingItemCurrencyISO } from '@/utilities/getTradingItemCurrencyISO';
import { NarrativeCardActions } from '../NarrativeCardActions';
import type { NARRATIVE_CARD_SYNTHETIC_FRAGMENT } from './narrativeCardSyntheticFragment';
import UserProfileOutline from '@simplywallst/ui-core/icons/UserProfileOutline';
import type { HeadingElements } from '@/utilities/heading';

const isValidValuationType = (
  type: string
): type is 'DCF' | 'ANALYSTS' | 'USER_DEFINED' =>
  ['DCF', 'ANALYSTS', 'USER_DEFINED'].includes(type);

export type Props = {
  linkTo?: string | RouterLinkAction | undefined;
  onClick?: () => void;
  onSetFairValueError?: () => void;
  className?: string;
  narrative: FragmentOf<typeof NARRATIVE_CARD_SYNTHETIC_FRAGMENT>;
  showCompanyIcon?: boolean;
  titleAs?: HeadingElements;
};

const iconWrapperClasses = cn(
  'grid size-8 items-center justify-center bg-p-grey-10'
);

type GetTypeSpecificPropsProps = {
  type: Props['narrative']['valuation']['type'] | null;
  priceTargetAnalystCount?: number | null;
};

const getTypeSpecificProps = ({
  type,
  priceTargetAnalystCount,
}: GetTypeSpecificPropsProps) => {
  if (type === 'DCF') {
    return {
      label: 'Discounted Cash Flow',
      subtitle: 'Simply Wall St',
      icon: (
        <div className={iconWrapperClasses}>
          <WarrenSolid className="w-6" />
        </div>
      ),
    };
  }

  return {
    label: 'Analyst Price Target',
    subtitle: getNarrativeAuthorRole({
      isAiNarrative: true,
      priceTargetAnalystCount,
    }),
    icon: (
      <div className={iconWrapperClasses}>
        <PriceTargetOutline className="w-6 fill-accent" />
      </div>
    ),
  };
};

export const NarrativeCardSynthetic = ({
  onClick,
  linkTo,
  showCompanyIcon,
  narrative,
  className,
  onSetFairValueError,
  titleAs = 'h3',
}: Props) => {
  const {
    id,
    title,
    slug,
    valuation,
    company,
    isPrimary,
    primaryCount,
    owner,
  } = narrative ?? {};
  const {
    fairValue,
    intrinsicDiscount,
    type: valuationTypeLowerCase,
  } = valuation ?? {};
  const { priceTargetAnalystCount } = company?.analysisValue ?? {};
  const { tickerSymbol, name, canonicalURL, info } = company ?? {};
  const { trackEvent } = useTracking();
  const valuationTypeUpperCase = valuationTypeLowerCase.toLocaleUpperCase();
  const valuationType = isValidValuationType(valuationTypeUpperCase)
    ? valuationTypeUpperCase
    : null;

  if (!narrative || !company || !canonicalURL || !valuationType) return null;

  const { icon, subtitle, label } = getTypeSpecificProps({
    type: valuationType,
    priceTargetAnalystCount,
  });
  const { country, industry, exchangeTicker, companyName } =
    getCompanyUrlSegments(canonicalURL);

  const currencyIso = getTradingItemCurrencyISO(company?.data?.currencyInfo);

  return (
    <div className={cn('overflow-hidden rounded-x1_5 bg-surface-2', className)}>
      <div
        className={cn(
          'relative grid gap-4 bg-surface-3 p-4',
          showCompanyIcon ? 'h-[230px]' : 'h-[189.5px]'
        )}
      >
        <div className="absolute right-x1 top-x1">
          <NarrativeCardDropdown
            narrativeId={id}
            valuationType={valuationType}
          />
        </div>
        {showCompanyIcon && (
          <RouterLinkOptimised
            className="focus-outline w-max no-underline decoration-solid hover:underline"
            to={{
              type: ROUTE_NARRATIVE_COMMUNITY,
              payload: {
                country,
                exchange_ticker: exchangeTicker,
                industry,
                companyname: companyName,
                slug,
              },
            }}
            onClick={() =>
              trackEvent({
                action: 'click',
                modifier: 'company',
                companyId: company?.id,
                uniqueSymbol: company?.tickerSymbol,
              })
            }
          >
            <div className="grid grid-cols-[max-content_auto] items-center gap-2 pr-x5">
              <CompanyIcon
                website={info?.url}
                tickerSymbol={tickerSymbol}
                size={20}
              />
              <span className="line-clamp-1 text-tiny text-soft">{name}</span>
            </div>
          </RouterLinkOptimised>
        )}

        <div className="pr-x5">
          <NarrativeAuthorBase title={label} subtitle={subtitle}>
            {icon}
          </NarrativeAuthorBase>
        </div>

        {linkTo ? (
          <RouterLink
            className="block h-x13 no-underline decoration-p-white hover:underline focus:underline"
            to={linkTo}
            onClick={onClick}
          >
            <Heading
              as={titleAs}
              className="m-0 line-clamp-3 text-base font-strong text-solid"
            >
              {title}
            </Heading>
          </RouterLink>
        ) : (
          <Heading
            as={titleAs}
            className="m-0 line-clamp-3 h-x13 text-base font-strong text-solid"
          >
            {title}
          </Heading>
        )}
      </div>
      <div className="grid h-44 gap-4 p-4">
        <div className="grid grid-cols-[max-content_max-content] gap-x2">
          <KeyMetric color="chart03">
            <div className="grid grid-rows-[max-content_max-content]">
              <div className="text-lg font-strong text-solid">
                {toCurrencyTruncated(fairValue || 0, 2, currencyIso ?? 'XXX')}
              </div>
              <div className="grid grid-cols-[max-content_max-content] items-center gap-1 text-tiny">
                <div className="text-soft">FV</div>

                <CompanyIntrinsicDiscountBase discount={intrinsicDiscount} />
              </div>
            </div>
          </KeyMetric>
          {typeof priceTargetAnalystCount === 'number' && (
            <KeyMetric color="brand01">
              <div className="grid grid-rows-[max-content_max-content]">
                <div className="text-lg font-strong text-solid">
                  {toTruncated(priceTargetAnalystCount, 0)}
                </div>
                <div className="text-tiny text-soft">Number of Analysts</div>
              </div>
            </KeyMetric>
          )}
        </div>
        <NarrativeCardActions
          narrativeId={id}
          companyId={company.id}
          publicId={owner.publicId}
          isPrimary={isPrimary}
          valuationType={valuationType}
          onError={onSetFairValueError}
        />

        <div className="grid grid-cols-2 gap-x2 fill-softer text-xs text-softer">
          <div className="grid grid-cols-[min-content_min-content] items-center gap-x0_5">
            <UserProfileOutline className="size-x2" />

            <div>
              {primaryCount}
              <span className="sr-only">
                users have followed this narrative
              </span>
            </div>
          </div>

          <div className="grid justify-end text-xs text-softer">
            Yesterday
            <div className="sr-only">SWS updated this narrative</div>
          </div>
        </div>
      </div>
    </div>
  );
};
