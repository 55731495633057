import type { ReactNode } from 'react';

import { useRef } from 'react';

import {
  useOverlayPosition,
  Overlay as AriaOverlay,
} from '@react-aria/overlays';

import type { AriaPositionProps } from '@react-aria/overlays';

import { Box } from '@simplywallst/ui-core';

import { Popover } from './Popover';

import { useMenuContext } from './MenuContext';

interface Props extends Pick<AriaPositionProps, 'placement'> {
  maxHeight?: number;
  children:
    | ReactNode
    | ((state: ReturnType<typeof useMenuContext>['overlayState']) => ReactNode);
}

export const Overlay = ({ placement, maxHeight = 350, children }: Props) => {
  const { triggerRef, overlayState, overlayProps } = useMenuContext();
  const overlayRef = useRef<HTMLDivElement>(null);
  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement,
    isOpen: overlayState.isOpen,
    onClose: () => null, // This stops the overlay from closing when the window scrolls. Hopefully this doesn't cause any issues. Closing on scroll isn't an issue IMO, however, it is not the same as the legacy menu implementation.
  });
  return overlayState.isOpen ? (
    <AriaOverlay>
      <Popover
        {...overlayProps}
        {...positionProps}
        ref={overlayRef}
        isOpen={overlayState.isOpen}
        onClose={overlayState.close}
      >
        <Box
          paddingY="x1"
          maxHeight={maxHeight}
          minWidth={200}
          overflow="auto"
          marginY="x1"
          backgroundColor="white"
          borderRadius="x0_5"
          className="shadow-lg"
        >
          {typeof children === 'function' ? children(overlayState) : children}
        </Box>
      </Popover>
    </AriaOverlay>
  ) : null;
};
