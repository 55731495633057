import type { NarrativeErrorCodeTranslated } from '../../utils/error';

export type NarrativeFeedType = 'stock' | 'weeklyPicks' | 'global';

export const ERROR_CODES_TO_FEED_TYPE = {
  narrativeNotFound: 'weeklyPicks',
  invalidInputRef: 'weeklyPicks',
  narrativeNotPublic: 'stock',
  narrativeBlocked: 'stock',
  narrativeSoftDeleted: 'stock',
  unknown: 'weeklyPicks',
} as const satisfies Record<NarrativeErrorCodeTranslated, NarrativeFeedType>;
