import { useGqlQueryInvalidate } from '@/utilities/gqlQuery';
import type { QueryTag } from '@/utilities/gqlQuery/types';
import { useCallback } from 'react';

const shouldInvalidate = (queryTags: QueryTag[], excludedTags: QueryTag[]) =>
  !excludedTags.some((tag) => queryTags.includes(tag)) &&
  queryTags.includes('narrative');

export const useNarrativeInvalidate = () => {
  const invalidateQueries = useGqlQueryInvalidate();

  const invalidate = useCallback(
    () => invalidateQueries(['narrative']),
    [invalidateQueries]
  );

  const invalidatePriority = useCallback(
    async (priorityInvalidationTags: QueryTag[]) => {
      await invalidateQueries(priorityInvalidationTags);
      /**
       * This invalidates all narrative queries except for the priority ones that were already invalidated.
       * It is not awaited on purpose so that it doesn't block the priority invalidation response.
       */
      invalidateQueries((queryTags) =>
        shouldInvalidate(queryTags, priorityInvalidationTags)
      );
    },
    [invalidateQueries]
  );

  const invalidateExcept = useCallback(
    (tagsToExclude: QueryTag[]) => {
      return invalidateQueries((queryTags) =>
        shouldInvalidate(queryTags, tagsToExclude)
      );
    },
    [invalidateQueries]
  );

  return {
    invalidatePriority,
    invalidateExcept,
    invalidate,
  };
};
