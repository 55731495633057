import type { ReactNode } from 'react';
import { Text, Button } from '@simplywallst/ui-core';
import { SWS_TERMS_AND_CONDITIONS } from '@/constants';
import { Content } from './styled';
import { Translation } from '@/hooks/useTranslation';

type Props = {
  children: ReactNode;
};

export const ActionsPanel = ({ children }: Props) => {
  return (
    <Content>
      <div>{children}</div>
      <div>
        <Text color={'darkGray'} typography={'tiny'} textAlign={'center'}>
          <Translation i18nKey="app.modal.registration.footer.termsAndConditions">
            By using Simply Wall St you are agreeing to our
            <Button
              as="a"
              styleType="softLinkReverse"
              rel="noopener noreferrer"
              href={SWS_TERMS_AND_CONDITIONS}
              target="_blank"
              data-cy-id="link-terms-and-conditions"
            >
              terms and conditions.
            </Button>
            Simply Wall St provides general investment advice only.
          </Translation>
        </Text>
      </div>
    </Content>
  );
};
