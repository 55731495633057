import { gql } from '@/utilities/gql';

export const NARRATIVE_CARD_SYNTHETIC_FRAGMENT = gql(`
  fragment NarrativeCardSyntheticFields on Narrative @_unmask {
    id
    title
    type
    slug
    isPrimary
    primaryCount
    valuation {
      type
      fairValue
      intrinsicDiscount
    }
    company {
      id
      tickerSymbol
      name
      canonicalURL
      data {
        currencyInfo
      }
      info {
        url
      }
      analysisValue {
        priceTargetAnalystCount
      }
    }
    owner {
      publicId
    }
  }
`);
