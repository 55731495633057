import { cloneElement, isValidElement } from 'react';

import type { ReactNode, ReactElement } from 'react';

import { useButton } from '@react-aria/button';

import { useMenuContext } from './MenuContext';

type Props = {
  label: ReactNode;
};

export const Trigger = ({ label }: Props) => {
  const { triggerRef, triggerProps } = useMenuContext();
  const { buttonProps } = useButton(triggerProps, triggerRef);

  return isValidElement<HTMLButtonElement>(label) ? (
    cloneElement(label as ReactElement, {
      ...buttonProps,
      ref: triggerRef,
    })
  ) : (
    <button ref={triggerRef} {...buttonProps}>
      {label}
    </button>
  );
};
