import { isCurrencyISO } from '@/constants/currencies';
import { isNil } from 'lodash/fp';

export const getTradingItemCurrencyISO = (currencyInfo: unknown) => {
  if (typeof currencyInfo !== 'object') return null;
  if (isNil(currencyInfo)) return null;

  if (
    'trading_item_currency_iso' in currencyInfo &&
    isCurrencyISO(currencyInfo.trading_item_currency_iso)
  ) {
    return currencyInfo['trading_item_currency_iso'];
  }

  return null;
};
