import { useTracking } from 'react-tracking';
import LikeOutline from '@simplywallst/ui-core/icons/LikeOutline';
import CommentOutline from '@simplywallst/ui-core/icons/CommentOutline';
import UserProfileOutline from '@simplywallst/ui-core/icons/UserProfileOutline';
import { toCurrencyTruncated, toTruncated } from '@/utilities/formatting';
import { CompanyIntrinsicDiscount } from '@/components/CompanyIntrinsicDiscount';
import { KeyMetric } from '@/components/KeyMetric';
import { NarrativeAuthor } from '@/components/NarrativeAuthor';

import {
  ROUTE_NARRATIVE_COMMUNITY,
  ROUTE_NARRATIVE_COMMUNITY_DETAIL,
} from '@/constants/routes';
import { useFastCommentsCount } from '@components/FastCommentWidget';

import type { FragmentOf } from 'gql.tada';
import { readFragment } from 'gql.tada';
import { NARRATIVE_CARD_FRAGMENT } from './narrativeCardFragment';
import { NarrativeCardDropdown } from './components/NarrativeCardDropdown';
import { Heading, cn } from '@simplywallst/ui-core/next';
import { getTradingItemCurrencyISO } from '@/utilities/getTradingItemCurrencyISO';
import { CompanyIcon } from '../CompanyIcon';
import {
  getCompanyUrlSegments,
  getNarrativeUrl,
} from '@/pages/Narrative/utils/getNarrativeUrl';
import { NarrativeCardTimeBadge } from './components/NarrativeCardTimeBadge';
import { NarrativeCardExcerpt } from './components/NarrativeCardExcerpt';
import { NarrativeCardActions } from './components/NarrativeCardActions';
import { usePathname } from '@/hooks/location/usePathname';
import { RouterLinkOptimised } from '../RouterLinkOptimised';
import type { HeadingElements } from '@/utilities/heading';

export type PopulatedStateBaseProps = {
  onClick?: () => void;
  onSetFairValueError?: () => void;
  className?: string;
  showCompanyIcon?: boolean;
  /**
   * The path to return to after editing a narrative.
   * Defaults to the current pathname
   */
  returnPath?: string;
  titleAs?: HeadingElements;
};

type Props = PopulatedStateBaseProps & {
  narrative: FragmentOf<typeof NARRATIVE_CARD_FRAGMENT>;
};

export const PopulatedState = ({
  narrative,
  onClick,
  onSetFairValueError,
  className,
  showCompanyIcon,
  returnPath,
  titleAs = 'h3',
}: Props) => {
  const { trackEvent } = useTracking();
  const {
    company,
    primaryCount,
    slug,
    updatedAt,
    lastFeaturedAt,
    title,
    owner,
    valuation,
    voteCount,
    isPublic,
    commentKey,
    type,
  } = readFragment(NARRATIVE_CARD_FRAGMENT, narrative);
  const { commentCount } = useFastCommentsCount(commentKey);
  const pathname = usePathname();
  const { analysisValue, data, name, info, tickerSymbol } = company ?? {};
  const currencyIso = getTradingItemCurrencyISO(data?.currencyInfo);
  const formattedFairValue = toCurrencyTruncated(
    valuation?.fairValue || 0,
    2,
    currencyIso ?? 'XXX'
  );

  const titleNode = (
    <Heading
      as={titleAs}
      className="m-0 line-clamp-2 text-base font-strong text-solid hover:underline focus:underline "
    >
      {title || `I believe ${name}'s Fair Value is ${formattedFairValue}`}
    </Heading>
  );

  const { canonicalURL } = company ?? { canonicalURL: '' };

  const { country, industry, exchangeTicker, companyName } =
    getCompanyUrlSegments(canonicalURL);

  return (
    <div className={cn('overflow-hidden rounded-x1_5 bg-surface-2', className)}>
      <div
        className={cn(
          'relative grid gap-4 bg-surface-3 p-x2 transition duration-300 ease-out hover:bg-p-grey-10 focus:bg-p-grey-10',
          showCompanyIcon ? 'h-[230px]' : 'h-[189.5px]'
        )}
      >
        <div className="absolute right-x1 top-x1">
          <NarrativeCardDropdown
            publicId={narrative?.owner?.publicId}
            narrativeId={narrative.id}
            canonicalUrl={getNarrativeUrl({
              canonicalURL,
              narrativeSlug: slug,
            })}
            valuationType="USER_DEFINED"
          />
        </div>
        {showCompanyIcon && (
          <RouterLinkOptimised
            className="focus-outline w-max no-underline decoration-solid hover:underline"
            to={{
              type: ROUTE_NARRATIVE_COMMUNITY,
              payload: {
                country,
                exchange_ticker: exchangeTicker,
                industry,
                companyname: companyName,
                slug,
              },
            }}
            onClick={() =>
              trackEvent({
                action: 'click',
                modifier: 'company',
                companyId: company?.id,
                uniqueSymbol: company?.tickerSymbol,
              })
            }
          >
            <div className="grid grid-cols-[max-content_auto] items-center gap-2 pr-x5">
              <CompanyIcon
                website={info?.url}
                tickerSymbol={tickerSymbol}
                size={20}
              />
              <span className="line-clamp-1 text-tiny text-soft">{name}</span>
            </div>
          </RouterLinkOptimised>
        )}
        <div className="pr-x5">
          <NarrativeAuthor
            owner={{
              displayName: owner.displayName,
              publicId: owner.publicId,
              imageUrl: owner.imageUrl,
              role: owner.role,
              disclaimerType: owner.disclaimerType,
            }}
            priceTargetAnalystCount={analysisValue?.priceTargetAnalystCount}
            isAiNarrative={type === 'AI'}
          />
        </div>
        {isPublic ? (
          <RouterLinkOptimised
            className="min-h-x13 no-underline decoration-p-white"
            to={{
              type: ROUTE_NARRATIVE_COMMUNITY_DETAIL,
              payload: {
                country,
                exchange_ticker: exchangeTicker,
                industry,
                companyname: companyName,
                slug,
              },
            }}
            onClick={onClick}
          >
            {titleNode}
            <NarrativeCardExcerpt>{narrative.excerpt}</NarrativeCardExcerpt>
          </RouterLinkOptimised>
        ) : (
          <>
            <div className="min-h-[81px]">{titleNode}</div>
            <NarrativeCardExcerpt>{narrative.excerpt}</NarrativeCardExcerpt>
          </>
        )}
      </div>
      <div className="grid h-44 gap-4 p-4">
        <div className="grid grid-cols-[max-content_max-content] gap-x2">
          <KeyMetric color="chart03">
            <div className="grid grid-rows-[max-content_max-content]">
              <div className="text-lg font-strong text-solid">
                {formattedFairValue}
              </div>
              <div className="grid grid-cols-[max-content_max-content] items-center gap-1 text-tiny">
                <div className="text-soft">FV</div>
                <CompanyIntrinsicDiscount
                  lastSharePrice={analysisValue?.lastSharePrice ?? null}
                  npvPerShare={analysisValue?.npvPerShare ?? null}
                  fairValue={valuation?.fairValue}
                />
              </div>
            </div>
          </KeyMetric>
          {typeof valuation?.revenuePercentageChange === 'number' && (
            <KeyMetric color="brand01">
              <div className="grid grid-rows-[max-content_max-content]">
                <div className="text-lg font-strong text-solid">
                  {toTruncated(valuation.revenuePercentageChange, 2)}%
                </div>
                <div className="text-tiny text-soft">Revenue growth p.a.</div>
              </div>
            </KeyMetric>
          )}
        </div>
        {company?.id && (
          <NarrativeCardActions
            narrativeId={narrative.id}
            companyId={company.id}
            publicId={narrative?.owner?.publicId}
            isPrimary={narrative.isPrimary}
            returnPath={returnPath || pathname}
            valuationType="USER_DEFINED"
            onError={onSetFairValueError}
          />
        )}
        <div className="grid grid-cols-2 justify-between gap-x2">
          <div className="grid grid-cols-[repeat(3,max-content)] justify-start gap-x2 fill-softer text-xs text-softer">
            <div className="grid grid-cols-[min-content_min-content] items-center gap-x0_5">
              <LikeOutline className="size-x2" />

              <div>
                {voteCount.upvotes}
                <span className="sr-only">users have liked this narrative</span>
              </div>
            </div>
            <div className="grid grid-cols-[min-content_min-content] items-center gap-x0_5">
              <CommentOutline className="size-x2" />

              <div>
                {commentCount}
                <span className="sr-only">
                  users have commented on this narrative
                </span>
              </div>
            </div>
            <div className="grid grid-cols-[min-content_min-content] items-center gap-x0_5">
              <UserProfileOutline className="size-x2" />

              <div>
                {primaryCount}
                <span className="sr-only">
                  users have followed this narrative
                </span>
              </div>
            </div>
          </div>
          <NarrativeCardTimeBadge
            updatedAt={updatedAt}
            lastFeaturedAt={lastFeaturedAt}
          />
        </div>
      </div>
    </div>
  );
};
