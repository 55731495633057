import { useState, useRef, useCallback } from 'react';

import { Alert, AlertAction } from '@simplywallst/ui-core';

import type { SnackbarItem as SnackbarItemType } from '../../types';

import { StyledSnackBarItem } from './styled';

function renderActions({
  actionLabel,
  actionCallback,
  secondaryActionLabel,
  secondaryActionCallback,
}: {
  actionLabel: SnackbarItemType['actionLabel'];
  actionCallback: SnackbarItemType['actionCallback'];
  secondaryActionLabel?: SnackbarItemType['secondaryActionLabel'];
  secondaryActionCallback?: SnackbarItemType['secondaryActionCallback'];
}) {
  const primaryAction = actionLabel ? (
    <AlertAction key={actionLabel} onClick={actionCallback}>
      {actionLabel}
    </AlertAction>
  ) : null;
  const secondaryAction = secondaryActionLabel ? (
    <AlertAction key={secondaryActionLabel} onClick={secondaryActionCallback}>
      {secondaryActionLabel}
    </AlertAction>
  ) : null;

  if (primaryAction && secondaryAction) {
    return [primaryAction, secondaryAction];
  }

  if (primaryAction) {
    return primaryAction;
  }

  return undefined;
}

type State = {
  kill: boolean;
  height: number;
  transitionIn: boolean;
  transitionOut: boolean;
};

type Props = {
  item: SnackbarItemType;
  handleRemove: (args: { id: string }) => void;
  handleTrackEvent: (attributes: Record<string, string | undefined>) => void;
};

const SnackbarItem = ({ item, handleRemove, handleTrackEvent }: Props) => {
  const {
    id,
    type,
    message,
    headline,
    lifeTime,
    actionLabel,
    actionCallback,
    secondaryActionLabel,
    secondaryActionCallback,
    layout,
  } = item;

  const ref = useRef<HTMLElement | null>(null);
  const [{ transitionIn, transitionOut, kill, height }, setState] =
    useState<State>({
      kill: false,
      height: 0,
      transitionIn: true,
      transitionOut: false,
    });

  const handleAnimationEnd = () => {
    if (transitionIn) {
      setState((prev) =>
        lifeTime > 0
          ? {
              ...prev,
              transitionIn: false,
              transitionOut: true,
            }
          : {
              ...prev,
              transitionIn: false,
            }
      );
    } else if (transitionOut) {
      handleRemove({ id });
      setState((prev) => ({ ...prev, transitionOut: false }));
    }
  };

  const handleTransitionOut = useCallback(() => {
    if (!transitionOut) {
      setState((prev) => ({ ...prev, transitionOut: true }));
    } else if (transitionOut && lifeTime > 0) {
      /**
       * RARE: There's a lifeTime to the snackbar and an actionLabel
       * we need to kill it early as the user has clicked an action.
       */
      setState((prev) => ({ ...prev, kill: true }));
    }
  }, [transitionOut, lifeTime]);

  const handleActionClick = useCallback(() => {
    handleTransitionOut();
    actionCallback?.();
    handleTrackEvent({
      action: 'click',
      label: actionLabel,
    });
  }, [handleTransitionOut, handleTrackEvent, actionLabel, actionCallback]);

  const handleSecondaryActionClick = useCallback(() => {
    handleTransitionOut();
    secondaryActionCallback?.();
    handleTrackEvent({
      action: 'click',
      label: secondaryActionLabel,
    });
  }, [
    handleTransitionOut,
    handleTrackEvent,
    secondaryActionCallback,
    secondaryActionLabel,
  ]);

  const measureSnackbarWrapper = useCallback(
    (element: HTMLElement | null) => {
      if (element && element.offsetHeight !== height) {
        setState((prev) => ({ ...prev, height: element.offsetHeight }));
      }
    },
    [height]
  );

  const refCallback = useCallback(
    (element: HTMLElement | null) => {
      ref.current = element;
      measureSnackbarWrapper(element);
    },
    [measureSnackbarWrapper]
  );

  return (
    <StyledSnackBarItem
      ref={refCallback}
      height={height}
      lifeTime={kill ? 0 : lifeTime}
      transitionIn={transitionIn}
      transitionOut={transitionOut}
      onAnimationEnd={handleAnimationEnd}
    >
      <Alert
        title={headline}
        styleType={type}
        data-cy-id="snackbar-message"
        layout={layout}
        actions={renderActions({
          actionLabel,
          actionCallback: handleActionClick,
          secondaryActionLabel,
          secondaryActionCallback: handleSecondaryActionClick,
        })}
      >
        {message}
      </Alert>
    </StyledSnackBarItem>
  );
};

export default SnackbarItem;
