import { useLocation } from '@/hooks/useLocation';
import { useUser } from '@/hooks/useUser';
import { ROUTE_PLANS } from '@/constants/routes';

const UTM_VARIANT_VALUE = 'UEVrM6c5BkW62UHs6hVq4Q';

export const useUtmExperiment = () => {
  const location = useLocation();
  const isUtmExperiment =
    location.type === ROUTE_PLANS &&
    location.query?.group === UTM_VARIANT_VALUE;
  return { isUtmExperiment };
};

export const useUtmExperimentOnGuest = () => {
  const { isUtmExperiment } = useUtmExperiment();
  const { data } = useUser();
  const { loggedIn } = data;

  const isEnabled = isUtmExperiment && !loggedIn;
  return { isEnabled };
};
