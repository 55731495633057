import { cn } from '@simplywallst/ui-core/next';

import { Button } from '@simplywallst/ui-core/next';
import EditSolid from '@simplywallst/ui-core/icons/EditSolid';
import { ROUTE_PUBLIC_NARRATIVE_EDIT } from '@/constants/routes';
import { getPath } from '@/router/utils';
import { useTracking } from 'react-tracking';
import { useSnackbar } from '@/features/snackbar';
import { NarrativeSetFairValue } from '@/pages/Narrative/components/NarrativeSetFairValue';
import { useCallback } from 'react';
import { useMe } from '@/components/User/components/Profile/components/CommunityProfile/hooks/useMe';
import { useUser } from '@/hooks/useUser';

type Props = {
  narrativeId: string;
  companyId: string;
  publicId: string;
  isPrimary: boolean;
  returnPath?: string;
  onError?: () => void;
  valuationType: 'DCF' | 'ANALYSTS' | 'USER_DEFINED';
};

export const NarrativeCardActions = ({
  narrativeId,
  companyId,
  publicId,
  isPrimary,
  returnPath,
  onError,
  valuationType,
}: Props) => {
  const { data: user } = useUser();
  const { data: me } = useMe({}, { enabled: user?.loggedIn });
  const isMyNarrative = me?.me?.communityProfile?.publicId === publicId;
  const { trackEvent } = useTracking();
  const { addSnackbar } = useSnackbar();

  const handleError = useCallback(() => {
    if (typeof onError === 'function') {
      onError();
      return;
    }
    addSnackbar({
      message: 'Failed to select fair value. Please try again.',
      type: 'negative',
      lifeTime: 10000,
    });
  }, [addSnackbar, onError]);

  return (
    <div
      className={cn(
        'grid gap-x1',
        isMyNarrative ? 'grid-cols-2' : 'grid-cols-1'
      )}
    >
      {isMyNarrative && (
        <Button
          variant="outline"
          size="large"
          href={getPath({
            type: ROUTE_PUBLIC_NARRATIVE_EDIT,
            payload: {
              id: narrativeId,
              query: {
                company: companyId.toLowerCase(),
                returnPath,
              },
            },
          })}
          className="!px-x0 text-center"
          onClick={() => {
            trackEvent({
              action: 'click',
              target: 'edit-narrative',
              valuationType: 'user_defined',
            });
          }}
        >
          <EditSolid className="!mr-1" />
          Edit Narrative
        </Button>
      )}
      <NarrativeSetFairValue
        companyId={companyId}
        narrativeId={narrativeId}
        isPrimary={isPrimary}
        onError={handleError}
        valuationType={valuationType}
      />
    </div>
  );
};
