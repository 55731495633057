import { createContext, useCallback, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useElementRemoved } from './hooks/useElementRemoved';
import loadable from '@loadable/component';
import { useAutoDismiss } from './hooks/useAutoDismiss';

const NarrativeWatchCompany = loadable(
  () => import('./NarrativeWatchCompany'),
  {
    resolveComponent: (m) => m.NarrativeWatchCompany,
  }
);

type OpenProps = {
  companyId: string;
  anchorElement?: HTMLElement | null;
};

type NarrativeWatchCompanyContextType = {
  open: (props: OpenProps) => void;
  close: () => void;
};

export const initialState: NarrativeWatchCompanyContextType = {
  open: () => {},
  close: () => {},
};

export const NarrativeWatchCompanyContext =
  createContext<NarrativeWatchCompanyContextType>(initialState);

type Props = {
  children: ReactNode;
};

export const NarrativeWatchCompanyProvider = ({ children }: Props) => {
  const [state, setState] = useState<{
    isOpen: boolean;
    companyId: string | null;
    anchorElement: HTMLElement | null;
  }>({
    isOpen: false,
    companyId: null,
    anchorElement: null,
  });

  const onElementRemoved = useCallback(
    () => setState((s) => ({ ...s, anchorElement: null })),
    []
  );

  useElementRemoved(state.anchorElement, onElementRemoved);

  const handleOpen = useCallback(
    ({
      companyId: newCompanyId,
      anchorElement: newAnchorElement,
    }: OpenProps) => {
      setState({
        isOpen: true,
        companyId: newCompanyId.toLowerCase(),
        anchorElement: newAnchorElement ?? null,
      });
    },
    []
  );

  const handleClose = useCallback(() => {
    setState((s) => ({ ...s, isOpen: false }));
  }, []);

  useAutoDismiss(handleClose, state.companyId);
  const contextValue = useMemo(
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    [handleOpen, handleClose]
  );

  return (
    <NarrativeWatchCompanyContext.Provider value={contextValue}>
      {children}
      {state.companyId && state.isOpen && (
        <NarrativeWatchCompany
          companyId={state.companyId}
          anchorElement={state.anchorElement}
        />
      )}
    </NarrativeWatchCompanyContext.Provider>
  );
};
