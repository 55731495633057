import { useEffect, useRef, useState } from 'react';
import { FallbackSvg, LogoWrapper, StyledImg } from './styled';
import { getCompanyLogoBackground } from './utils';
import { getCloudflareImageUrl } from '@/hooks/useCloudflareImage';

interface CompanyIconProps {
  website?: string | null;
  tickerSymbol?: string;
  size?: number;
  sizeLg?: number;
  isLazyLoaded?: boolean;
}

/**
 * Temp fix to improve the display of our company logos
 * @todo Avoid this, incorporate into the `getCloudflareImageUrl` function instead?
 * @issue https://simplywallst.atlassian.net/browse/RSRCH-2409
 */
const replaceWebsite = (website: string) =>
  website.replace(
    /\.interactivebrokers\.com$/,
    '.interactivebrokers.com.au' // Non-transparent + smaller logo
  );

export const CompanyIcon = ({
  website,
  tickerSymbol = 'XX',
  size = 46,
  sizeLg = size,
  isLazyLoaded = false,
}: CompanyIconProps) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const maxSize = Math.max(size, sizeLg);
  const prefixedWebsite =
    website && !website.startsWith('https://') ? `https://${website}` : website;

  const imgSrc = prefixedWebsite
    ? getCloudflareImageUrl({
        src: `https://logo.clearbit.com/${replaceWebsite(
          prefixedWebsite
        )}?size=${maxSize * 2}`,
      })
    : null;

  useEffect(() => {
    if (!imgSrc) return;

    // Image loaded from cache
    if (imageRef.current?.complete) {
      setLoaded(true);
      setError(false);
      return;
    }
    setLoaded(false);
    setError(false);
  }, [imgSrc]);

  const ticker = `${tickerSymbol}` || '';
  const isLoading = Boolean(imgSrc && !loaded && !error);
  const showPlaceholder = error || !imgSrc;

  return (
    <LogoWrapper
      $isLoading={isLoading}
      $size={size}
      $sizeLg={sizeLg}
      itemProp="logo"
      itemType="https://schema.org/ImageObject"
      aria-label={`${ticker} logo`}
    >
      {showPlaceholder ? (
        <FallbackSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <rect
            fill={`#${getCompanyLogoBackground(ticker)}`}
            cx="32"
            width="64"
            height="64"
            cy="32"
            r="32"
          />
          <text
            x="50%"
            y="50%"
            alignmentBaseline="middle"
            textAnchor="middle"
            fontSize="21"
            fontWeight="400"
            dy=".1em"
            dominantBaseline="middle"
            fill="#ffffff"
          >
            {ticker.substring(0, 4)}
          </text>
        </FallbackSvg>
      ) : (
        <>
          <StyledImg
            ref={imageRef}
            src={imgSrc}
            alt={`${tickerSymbol} logo`}
            role="img"
            $isLoading={isLoading}
            onLoad={() => {
              setLoaded(true);
              setError(false);
            }}
            onError={() => setError(true)}
            loading={isLazyLoaded ? 'lazy' : undefined}
          />
          <meta content={imgSrc} itemProp="url" />
        </>
      )}
    </LogoWrapper>
  );
};
