import type { FragmentOf } from '@/utilities/gql';
import { gql } from '@/utilities/gql';
import type { PopulatedStateBaseProps } from './PopulatedState';
import { PopulatedState } from './PopulatedState';
import { NARRATIVE_CARD_FRAGMENT } from './narrativeCardFragment';
import type { NarrativeCardSyntheticProps } from './components/NarrativeCardSynthetic';
import {
  NARRATIVE_CARD_SYNTHETIC_FRAGMENT,
  NarrativeCardSynthetic,
} from './components/NarrativeCardSynthetic';

export const NARRATIVE_CARD_ISOMORPHIC_FRAGMENT = gql(
  `
  fragment NarrativeCardIsomorphicFields on Narrative @_unmask {
    ...NarrativeCardFields
    ...NarrativeCardSyntheticFields
  }
`,
  [NARRATIVE_CARD_FRAGMENT, NARRATIVE_CARD_SYNTHETIC_FRAGMENT]
);

type Props = (
  | PopulatedStateBaseProps
  | Omit<NarrativeCardSyntheticProps, 'narrative'>
) & {
  narrative: FragmentOf<typeof NARRATIVE_CARD_ISOMORPHIC_FRAGMENT>;
};

export const NarrativeCardIsomorphic = (props: Props) => {
  const { narrative } = props;

  if (['dcf', 'analysts'].includes(narrative.valuation.type)) {
    return <NarrativeCardSynthetic {...props} />;
  }

  return <PopulatedState {...props} />;
};
