import { SWS_HOST } from '@/constants';
import { useTypeformDrawer } from '@/hooks/useTypeformDrawer';

export const useReportNarrative = ({
  narrativeId,
  canonicalUrl,
  narrativeType,
}: {
  narrativeId: string;
  canonicalUrl: string;
  narrativeType: string;
}) => {
  const reportNarrativeWithTypeformDrawer = useTypeformDrawer('QcdSFNqs', {
    narrativeId,
    narrativeurl: canonicalUrl ? `${SWS_HOST}${canonicalUrl}` : undefined,
    valuationtype: narrativeType,
  });

  return {
    reportNarrativeWithTypeformDrawer,
  };
};
