import { NarrativeDisclaimerBadge } from '@/pages/Narrative/components/NarrativeDisclaimerBadge';
import type { FragmentOf } from '@/utilities/gql';
import { gql } from '@/utilities/gql';

import { NarrativeAuthorBase } from './components/NarrativeAuthorBase';
import { NarrativeAuthorAvatar } from './components/NarrativeAuthorAvatar';
import { getNarrativeAuthorRole } from './utils';

export const NARRATIVE_OWNER_FRAGMENT = gql(`
  fragment NarrativeOwnerFields on CommunityProfile @_unmask {
    publicId
    displayName
    imageUrl
    role
    disclaimerType
  }
`);

type Props = {
  owner?: FragmentOf<typeof NARRATIVE_OWNER_FRAGMENT>;
  userHoldsPosition?: boolean | null;
  priceTargetAnalystCount?: number | null;
  isAiNarrative?: boolean;
};

export const NarrativeAuthor = ({
  owner,
  userHoldsPosition,
  priceTargetAnalystCount,
  isAiNarrative,
}: Props) => {
  if (!owner) return null;

  const { displayName, imageUrl, publicId, role } = owner;

  const url = publicId ? `/community/users/${publicId}` : null;
  const title = isAiNarrative ? 'Analyst Price Target' : displayName || 'User';

  return (
    <NarrativeAuthorBase
      title={title}
      subtitle={getNarrativeAuthorRole({
        isAiNarrative,
        priceTargetAnalystCount,
        role,
      })}
      badge={<NarrativeDisclaimerBadge userHoldsPosition={userHoldsPosition} />}
      url={url}
      publicId={publicId}
    >
      <NarrativeAuthorAvatar
        size="md"
        owner={{ displayName: title, imageUrl }}
      />
    </NarrativeAuthorBase>
  );
};
