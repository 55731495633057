import { forwardRef } from 'react';

import type { ReactNode, RefObject } from 'react';

import { useOverlay, DismissButton } from '@react-aria/overlays';

import type { AriaOverlayProps } from '@react-aria/overlays';

import type {
  useOverlayTrigger,
  useOverlayPosition,
} from '@react-aria/overlays';

import { mergeProps } from '@react-aria/utils';

import { Box } from '@simplywallst/ui-core';

type TriggerProps = ReturnType<typeof useOverlayTrigger>['overlayProps'];
type PositionProps = ReturnType<typeof useOverlayPosition>['overlayProps'];

interface Props
  extends Pick<AriaOverlayProps, 'isOpen' | 'onClose'>,
    TriggerProps,
    PositionProps {
  children: ReactNode;
}

export const Popover = forwardRef<HTMLDivElement, Props>(
  ({ children, isOpen, onClose, ...triggerAndPositionProps }, ref) => {
    const { overlayProps } = useOverlay(
      {
        onClose,
        isOpen,
        isDismissable: true,
      },
      ref as RefObject<HTMLDivElement>
    );

    const { style: overlayStyles, ...overlay } = overlayProps;
    const { style: positionStyles, ...position } = triggerAndPositionProps;

    return (
      <Box
        {...mergeProps(overlay, position)}
        ref={ref}
        style={{
          ...overlayStyles,
          ...positionStyles,
        }}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </Box>
    );
  }
);

Popover.displayName = 'MenuPopover';
