import { Grid, Box } from '@simplywallst/ui-core';

import type { ReactNode, ComponentProps } from 'react';

interface Props {
  children: NonNullable<ReactNode>;
  color?: ComponentProps<typeof Box>['backgroundColor'];
}

export const KeyMetric = ({ color = 'brand01', children }: Props) => {
  return (
    <Grid gap="12px" templateColumns="4px max-content">
      <Box width="4px" backgroundColor={color} borderRadius="x1_25" />
      <Box>{children}</Box>
    </Grid>
  );
};
