import { useRef } from 'react';

import { useTreeState } from '@react-stately/tree';
import type { TreeProps } from '@react-stately/tree';

import { MenuItem } from './MenuItem';
import { ListSection } from './ListSection';

import type { useMenuTrigger } from '@react-aria/menu';
import { useMenu } from '@react-aria/menu';

import { StyledUl } from './styled';
import { useMenuContext } from './MenuContext';

type MenuProps<T extends object> = Pick<
  ReturnType<typeof useMenuTrigger<T>>,
  'menuProps'
>['menuProps'];

interface Props<T extends object>
  extends Pick<
      TreeProps<T>,
      'children' | 'items' | 'selectedKeys' | 'selectionMode'
    >,
    MenuProps<T> {}

export const List = <T extends object>({
  items,
  selectedKeys,
  selectionMode,
  children,
  onAction,
}: Props<T>) => {
  const { menuProps: menuOptions, overlayState } = useMenuContext();

  const treeState = useTreeState({
    children,
    items,
    selectedKeys,
    selectionMode,
  });

  const ref = useRef(null);

  const { menuProps } = useMenu(
    {
      ...menuOptions,
      onClose: overlayState.close,
      onAction,
    },
    treeState,
    ref
  );

  return (
    <StyledUl {...menuProps} ref={ref}>
      {[...treeState.collection].map((item) => {
        if (item.type === 'section') {
          return <ListSection key={item.key} item={item} state={treeState} />;
        }
        return <MenuItem key={item.key} item={item} state={treeState} />;
      })}
    </StyledUl>
  );
};
