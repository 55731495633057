import { Button } from '@simplywallst/ui-core/next';
import CheckSolid from '@simplywallst/ui-core/icons/CheckSolid';
import GuestRegistrationModal from '@/components/GuestRegistrationModal';
import { NarrativePanelRegister } from '@/components/GuestRegistrationModal/components/NarrativePanelRegister';
import { useNarrativeTogglePrimary } from '../../hooks/useNarrativeTogglePrimary';

type Props = {
  narrativeId: string;
  companyId: string;
  isPrimary: boolean;
  onError: () => void;
  valuationType: 'USER_DEFINED' | 'DCF' | 'ANALYSTS';
};

export const NarrativeSetFairValue = ({
  narrativeId,
  companyId,
  isPrimary,
  onError,
  valuationType,
}: Props) => {
  const {
    isSaving,
    isGuestRegistrationModalOpen,
    togglePrimary,
    setGuestRegistrationModal,
    triggerRef,
  } = useNarrativeTogglePrimary({
    companyId,
    narrativeId,
    valuationType,
    isPrimary,
    onError,
  });

  const renderButtonText = () => {
    if (isSaving) {
      return 'Saving';
    }

    return isPrimary ? (
      <>
        <CheckSolid />
        Fair Value
      </>
    ) : (
      'Set Fair Value'
    );
  };

  return (
    <>
      <Button
        isLoading={isSaving}
        isDisabled={isSaving}
        variant={isPrimary ? 'secondary' : 'outline'}
        size="large"
        onClick={togglePrimary}
        ref={triggerRef}
      >
        {renderButtonText()}
      </Button>
      {isGuestRegistrationModalOpen && (
        <GuestRegistrationModal
          onClose={() => setGuestRegistrationModal(false)}
          onLoginSuccess={() => setGuestRegistrationModal(false)}
          onRegisterSuccess={() => setGuestRegistrationModal(false)}
          canClose
          removeWindowScroll
          SplashPanel={{
            Component: NarrativePanelRegister,
            isDark: true,
          }}
        />
      )}
    </>
  );
};
