import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { NarrativeInvalidationBoundary } from '../NarrativeInvalidationBoundary';
import { NarrativeNotFoundSkeleton } from './components/NarrativeNotFoundSkeleton';
import { NarrativeNotFoundMain } from './components/NarrativeNotFoundMain';
import DocumentHeader from '@/components/DocumentHeader';

export const NarrativeNotFound = () => {
  return (
    <div className="grid w-full max-w-screen-xl grid-cols-1 place-content-center place-items-center gap-x1 p-x2 @container/main lg:p-x3">
      <DocumentHeader title="404: Narrative Not Found" />
      <div className="rotate-90 text-[164px] font-bold text-accent">{':('}</div>
      <h1 className="my-0 text-pretty text-center text-mass font-strong text-solid">
        404: Narrative Not Found
      </h1>
      <div className="text-pretty text-center text-xl text-soft">
        This narrative is no longer available. Meanwhile, check out the
        narratives below.
      </div>

      <ErrorBoundary>
        <Suspense fallback={<NarrativeNotFoundSkeleton />}>
          <NarrativeInvalidationBoundary
            onSetPrimary={({ invalidatePriority }) =>
              invalidatePriority(['narrative-not-found'])
            }
          >
            <NarrativeNotFoundMain />
          </NarrativeInvalidationBoundary>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
