import { useEffect } from 'react';

export const useElementRemoved = (
  element: HTMLElement | null,
  onRemove: () => void
) => {
  useEffect(() => {
    if (!element) return;

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        mutation.removedNodes.forEach((node) => {
          if (
            node &&
            typeof node.contains === 'function' &&
            node.contains(element)
          ) {
            onRemove();
          }
        });
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [element, onRemove]);
};
