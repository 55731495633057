import type { OnSetPrimaryPayload } from './NarrativeInvalidationBoundary';

type NarrativeLike = {
  id: string;
  isPrimary: boolean;
  primaryCount: number;
  company: {
    id: string;
  } | null;
};

export const getIsPrimary = <T extends NarrativeLike>(
  narrative: T,
  { unset, narrativeId, companyId }: OnSetPrimaryPayload
) => {
  if (unset) {
    if (narrative.company?.id === companyId) return false;
    return narrative.isPrimary;
  }
  if (narrative.id === narrativeId) return true;
  if (narrative.company?.id === companyId) return false;

  return narrative.isPrimary;
};

export const getPrimaryCount = <T extends NarrativeLike>(
  narrative: T,
  { unset, narrativeId, companyId }: OnSetPrimaryPayload
) => {
  if (unset) {
    if (narrative.id === narrativeId)
      return Math.max(0, narrative.primaryCount - 1);
    return narrative.primaryCount;
  }
  if (narrative.id === narrativeId) return narrative.primaryCount + 1;
  if (narrative.company?.id === companyId)
    return Math.max(0, narrative.primaryCount - 1);
  return narrative.primaryCount;
};
