import { gql } from '@/utilities/gql';
import { makeGqlMutation } from '@/utilities/gqlQuery';

const MUTATION = gql(`
  mutation NarrativeUnsetPrimary($companyId: ID!) {
    unsetPrimaryNarrative(companyId: $companyId) {
      id
      isPrimary
    }
  }
`);

export const useNarrativeUnsetPrimary = makeGqlMutation(MUTATION);
