import { useCallback } from 'react';
import { useTracking } from 'react-tracking';

import { Portal } from '@simplywallst/ui-core';

import { useSnackbar } from '../context';
import SnackbarItem from './SnackbarItem';

export const Snackbar = () => {
  const { state, removeSnackbar } = useSnackbar();
  const { Track, trackEvent } = useTracking({
    type: 'track',
    subject: 'SnackBar',
  });

  const item =
    state.keys.length >= 1 ? state.entities[state.keys[0]] : undefined;

  const handleTrackEvent = useCallback(
    (event: Record<string, string | undefined>) => {
      if (item?.name) {
        trackEvent({
          type: 'track',
          modifier: item.name,
          ...event,
        });
      }
    },
    [item, trackEvent]
  );

  if (typeof item === 'undefined') {
    return null;
  }

  return (
    <Portal portalId={item.id}>
      <Track>
        <SnackbarItem
          key={item.id}
          item={item}
          handleRemove={removeSnackbar}
          handleTrackEvent={handleTrackEvent}
        />
      </Track>
    </Portal>
  );
};
