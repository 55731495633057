import { useCallback, useRef } from 'react';

import { useState } from 'react';
import { useNarrativeInvalidationBoundary } from '../components/NarrativeInvalidationBoundary';
import { useNarrativeSetPrimary } from './useNarrativeSetPrimary';
import { useNarrativeUnsetPrimary } from './useNarrativeUnsetPrimary';
import { useTracking } from 'react-tracking';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '@/features/snackbar/context';
import { useNarrativeWatchCompanyContext } from '../components/NarrativeWatchCompany';
import useUser from '@/hooks/useUser';
import { makeGqlQuery } from '@/utilities/gqlQuery';
import { gql } from '@/utilities/gql';

const UNDO_SET_FAIR_VALUE_SNACKBAR_ID = 'UNDO_SET_FAIR_VALUE';

const QUERY = gql(`
  query NarrativePrimaryByCompanyId($companyId: ID!) {
    company(ref: $companyId) {
      myValuation {
        narrative {
          id
        }
      }
    }
  }
`);

const useNarrativePrimaryByCompanyId = makeGqlQuery(QUERY, {
  tags: ['narrative'],
});

type Props = {
  companyId: string;
  narrativeId: string;
  valuationType: string;
  isPrimary: boolean;
  onError?: () => void;
};

export const useNarrativeTogglePrimary = ({
  companyId,
  narrativeId,
  valuationType,
  isPrimary,
  onError,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isGuestRegistrationModalOpen, setGuestRegistrationModal] =
    useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const { mutateAsync: setFairValue } = useNarrativeSetPrimary();
  const { mutateAsync: unsetFairValue } = useNarrativeUnsetPrimary();
  const { invalidateSetPrimary } = useNarrativeInvalidationBoundary();
  const { trackEvent } = useTracking();
  const { data: user, queryStatus } = useUser();
  const { addSnackbar, removeSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { open, close } = useNarrativeWatchCompanyContext();

  const handleUnsetFairValue = useCallback(async () => {
    removeSnackbar({ id: UNDO_SET_FAIR_VALUE_SNACKBAR_ID });
    setIsSaving(true);
    try {
      close();
      await unsetFairValue({
        companyId,
      });
      await invalidateSetPrimary({ narrativeId, companyId, unset: true });

      trackEvent({
        action: 'unset',
        companyId,
        valuationType,
        narrativeId,
      });
    } catch (err) {
      console.error('Unable to unset narrative as primary', err);
      onError?.();
    }
    setIsSaving(false);
  }, [
    unsetFairValue,
    invalidateSetPrimary,
    close,
    trackEvent,
    companyId,
    valuationType,
    narrativeId,
    onError,
    removeSnackbar,
  ]);

  const handleSetFairValue = useCallback(
    async (narrativeId: string) => {
      setIsSaving(true);
      try {
        const response = await queryClient.fetchQuery(
          useNarrativePrimaryByCompanyId.getOptions({ companyId })
        );
        const currentPrimaryNarrativeId =
          response.company?.myValuation?.narrative?.id;
        await setFairValue({
          narrativeId,
        });
        open({ companyId, anchorElement: triggerRef.current });

        await invalidateSetPrimary({ narrativeId, companyId });

        addSnackbar({
          id: UNDO_SET_FAIR_VALUE_SNACKBAR_ID,
          message: 'Fair value selected',
          actionLabel: 'Undo',
          lifeTime: 6000,
          type: 'positive',
          actionCallback: () => {
            currentPrimaryNarrativeId
              ? handleSetFairValue(currentPrimaryNarrativeId)
              : handleUnsetFairValue();
          },
        });
        trackEvent({
          action: 'save',
          companyId,
          valuationType,
          narrativeId,
        });
      } catch (err) {
        console.error('Unable to set narrative as primary', err);
        onError?.();
      }
      setIsSaving(false);
    },
    [
      queryClient,
      invalidateSetPrimary,
      setFairValue,
      open,
      trackEvent,
      companyId,
      valuationType,
      onError,
      addSnackbar,
      handleUnsetFairValue,
    ]
  );

  const togglePrimary = useCallback(async () => {
    if (!user.loggedIn) return setGuestRegistrationModal(true);

    if (isPrimary) {
      await handleUnsetFairValue();
    } else {
      await handleSetFairValue(narrativeId);
    }
  }, [isPrimary, handleSetFairValue, handleUnsetFairValue, narrativeId, user]);

  return {
    isLoading: queryStatus === 'pending',
    isSaving,
    isGuestRegistrationModalOpen,
    setGuestRegistrationModal,
    togglePrimary,
    triggerRef,
  };
};
