import { useCallback } from 'react';
import type { ReactNode } from 'react';
import { useTracking } from 'react-tracking';

import { NarrativeAuthorLink } from '../NarrativeAuthorLink';
import { cn } from '@simplywallst/ui-core/next';

type Props = {
  /**
   * The content to display in the author's avatar.
   */
  children: ReactNode;
  title: ReactNode;
  subtitle: ReactNode;
  badge?: ReactNode;
  url?: string | null;
  titleClassName?: string;
  publicId?: string;
};

export const NarrativeAuthorBase = ({
  children,
  title,
  subtitle,
  badge,
  url,
  titleClassName,
  publicId,
}: Props) => {
  const { Track, trackEvent } = useTracking({ publicId });

  const onClick = useCallback(() => {
    trackEvent({
      modifier: 'author',
      action: 'click',
      publicId,
    });
  }, [publicId, trackEvent]);

  return (
    <Track>
      <div className="grid grid-cols-[max-content_auto] items-center justify-items-start gap-2">
        <div className="relative grid content-stretch justify-stretch overflow-hidden rounded-full">
          {children}
        </div>

        <div>
          <div
            className={cn(
              'grid grid-cols-[auto_min-content] items-center gap-x1 text-sm text-solid',
              titleClassName
            )}
          >
            <NarrativeAuthorLink url={url} onClick={onClick}>
              <span className="line-clamp-1">{title}</span>
            </NarrativeAuthorLink>
            {badge}
          </div>
          {subtitle && <div className="text-tiny text-softer">{subtitle}</div>}
        </div>
      </div>
    </Track>
  );
};
