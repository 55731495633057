import { FastCommentsCommentWidget } from 'fastcomments-react';
import type {
  FastCommentsSSOSimple,
  FastCommentsWidgetComment,
} from 'fastcomments-typescript';
import type { ProfileState } from '@/hooks/useUser';
import { CommentWidgetTranslations } from '../constants';
import { FASTCOMMENTS_APP_ID, PUBLIC_SWS_ENV } from '@/constants/env';

interface CommentProps {
  urlId: string;
  userData: ProfileState;
  setShowRegModal: (show: boolean) => void;
  onCommentSubmitStart?: (
    comment: FastCommentsWidgetComment,
    continueSubmitFn: () => void,
    cancelFn: () => void
  ) => void;
  commentCountUpdated?: (count: number) => void;
}

const FastCommentsWidget = ({
  urlId,
  setShowRegModal,
  userData,
  onCommentSubmitStart,
  commentCountUpdated,
}: CommentProps) => {
  // https://docs.fastcomments.com/guide-customizations-and-configuration.html#sso-simple
  const info: FastCommentsSSOSimple = {
    username: userData.givenName,
    email: userData.emailAddress,
    avatar: userData.imageUrl,
    loginCallback: () => {
      setShowRegModal(true);
    },
  };

  return (
    <FastCommentsCommentWidget
      translations={{
        LOG_IN_TO_COMMENT: CommentWidgetTranslations.LOG_IN_TO_COMMENT,
        LOG_IN: CommentWidgetTranslations.LOG_IN_TO_COMMENT,
      }}
      tenantId={FASTCOMMENTS_APP_ID}
      onCommentSubmitStart={onCommentSubmitStart}
      commentCountUpdated={commentCountUpdated}
      debugRenderTime={PUBLIC_SWS_ENV !== 'prod'}
      urlId={urlId}
      simpleSSO={info}
    />
  );
};

export default FastCommentsWidget;
