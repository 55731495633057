import type { ComponentPropsWithRef, ReactNode } from 'react';

import type { OverlayTriggerProps } from '@react-stately/overlays';

import { Trigger } from './Trigger';
import { List } from './List';

import type { AriaPositionProps } from '@react-aria/overlays';

import { Overlay } from './Overlay';

import { MenuProvider } from './MenuContext';

type ListProps<T extends object> = ComponentPropsWithRef<typeof List<T>>;

interface Props<T extends object>
  extends Pick<
      ListProps<T>,
      'onAction' | 'children' | 'items' | 'selectedKeys' | 'selectionMode'
    >,
    Pick<OverlayTriggerProps, 'defaultOpen' | 'onOpenChange'>,
    Pick<AriaPositionProps, 'placement'> {
  label: ReactNode;
}
export const Menu = <T extends object>({
  label,
  items,
  defaultOpen,
  selectedKeys,
  selectionMode,
  placement = 'bottom start',
  onAction,
  onOpenChange,
  children,
}: Props<T>) => (
  <MenuProvider onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
    <Trigger label={label} />
    <Overlay placement={placement}>
      <List
        onAction={onAction}
        items={items}
        selectedKeys={selectedKeys}
        selectionMode={selectionMode}
      >
        {children}
      </List>
    </Overlay>
  </MenuProvider>
);
