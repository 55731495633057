import type { ReactNode } from 'react';
import { RouterLinkOptimised } from '@/components/RouterLinkOptimised';

type Props = {
  children: ReactNode;
  url?: string | null;
  onClick?: () => void;
};

export const NarrativeAuthorLink = ({ children, url, onClick }: Props) => {
  if (!url) {
    return <>{children}</>;
  }

  return (
    <RouterLinkOptimised
      className="text-p-white no-underline decoration-p-white hover:underline focus:underline"
      onClick={onClick}
      to={url}
    >
      {children}
    </RouterLinkOptimised>
  );
};
