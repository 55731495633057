import { Badge } from '@simplywallst/ui-core';

type Props = {
  userHoldsPosition: boolean | null | undefined;
};

export const NarrativeDisclaimerBadge = ({ userHoldsPosition }: Props) => {
  if (userHoldsPosition === true) {
    return <Badge tone="good">Invested</Badge>;
  }

  if (userHoldsPosition === false) {
    return <Badge tone="bad">Not Invested</Badge>;
  }

  return null;
};
