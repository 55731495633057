import { useState } from 'react';

import { useCloudflareImage } from '@/hooks/useCloudflareImage';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const avatar = tv({
  base: 'relative grid content-center items-center justify-center overflow-hidden rounded-full bg-p-white-170 shadow-border-strong',
  variants: {
    size: {
      xs: 'size-x2 text-micro',
      sm: 'size-6 text-xs',
      md: 'size-8 text-sm',
      lg: 'size-14 text-lg',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

type AvatarProps = VariantProps<typeof avatar>;

type Props = {
  owner: {
    displayName: string | null;
    imageUrl: string | null;
  };
} & AvatarProps;

const imageSize = (size: AvatarProps['size']) => {
  switch (size) {
    case 'xs':
      return 16;
    case 'sm':
      return 24;
    case 'lg':
      return 56;
    case 'md':
    default:
      return 32;
  }
};

export const NarrativeAuthorAvatar = ({ owner, size = 'md' }: Props) => {
  const { displayName, imageUrl } = owner;
  const [loaded, setLoaded] = useState(false);
  const cloudflareImageSrc = useCloudflareImage({
    src: imageUrl,
    width: imageSize(size) * 2,
    height: imageSize(size) * 2,
  });

  const initials =
    displayName !== null
      ? displayName.substring(0, 2).toUpperCase()
      : 'Simply Wall St User'.substring(0, 2).toUpperCase();

  // FIXME BE team is working on image upload functionality and after that knowing wheter we have an avatar will be exposed via a schema
  const hasAvatar =
    imageUrl &&
    imageUrl !== 'https://media.simplywall.st/news/1706674307668-no-image.png';

  return (
    <div className={avatar({ size })}>
      <div className="text-center text-solid">{initials}</div>
      {hasAvatar && (
        <div className="absolute inset-0">
          <img
            className={loaded ? 'visible size-full' : 'invisible'}
            src={cloudflareImageSrc}
            onLoad={() => setLoaded(true)}
          />
        </div>
      )}
    </div>
  );
};
